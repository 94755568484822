import React, { useState } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'

const hiddenColumnsInitially = [
  'Country',
  'Technology',
  'TechnologyGroup',
  'TSO',
  'BalancingCircle',
  'PlantGroup',
  'ActiveFromTimeUTC',
  'ActiveToTimeUTC',
  'AssociatedMaxPowerKW',
  'BasedOn',
  'insertion_time',
]

const typeNumberColumns = [
  'InstalledCapacityKW',
  'Lat',
  'Lon',
  'AssociatedMaxPowerKW',
]

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />{' '}
    </GridToolbarContainer>
  )
}

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
}

const PortfolioOverviewTable = props => {
  const { data, selectedPlants, setSelectedPlants } = props

  const columns = Object.keys(data[0]).map(key => ({
    field: key,
    flex: 1,
    type: typeNumberColumns.includes(key) ? 'number' : '',
  }))

  const initialColumnVisibilityModel = Object.keys(data[0]).reduce(
    (acc, key) => {
      acc[key] = !hiddenColumnsInitially.includes(key)
      return acc
    },
    {},
  )
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel,
  )

  // Change time output format
  const formattedData = data.map(obj => {
    const formatDate = timestamp => {
      return new Date(timestamp).toISOString().slice(0, 16).replace('T', ' ')
    }

    return {
      ...obj,
      Lat: obj.Lat.toFixed(3),
      Lon: obj.Lon.toFixed(3),
      ActiveFromTimeUTC: formatDate(obj.ActiveFromTimeUTC),
      ActiveToTimeUTC: formatDate(obj.ActiveToTimeUTC),
      insertion_time: formatDate(obj.insertion_time * 1000),
    }
  })

  return (
    <DataGrid
      rows={formattedData}
      rowHeight={30}
      columns={columns}
      getRowId={row => row.Name}
      checkboxSelection
      rowSelectionModel={selectedPlants}
      onRowSelectionModelChange={setSelectedPlants}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel =>
        setColumnVisibilityModel(newModel)
      }
      disableColumnMenu
      slots={{ toolbar: CustomToolbar }}
      sx={overridesWeirdResizing}
    />
  )
}

export default PortfolioOverviewTable
