export const saveStateToLocalStorage = (state, key) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(key, serializedState)
  } catch (error) {
    console.error('Error saving state to localStorage:', error)
  }
}

export const loadStateFromLocalStorage = key => {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    console.error('Error loading state from localStorage:', error)
    return undefined
  }
}

export const clearStateFromLocalStorage = () => {
  try {
    localStorage.clear()
  } catch (error) {
    console.error('Error clearing user state from localStorage:', error)
  }
}
