import axios from 'axios'
import { transformSelectedSetup } from '../api/utils'

const API_URL = `${process.env.REACT_APP_API_URL}`

const History = {
  getHistory(setup) {
    return axios({
      method: 'get',
      url: `${API_URL}/history/${transformSelectedSetup(setup)}`,
    })
  },
}

export default History
