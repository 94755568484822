import createIcon from './utils'

export const conwxLogoIcon = createIcon({
  iconType: 'conwx-logo-icon',
  path: 'M25.13,12.86v1A5.15,5.15,0,0,1,20,19h-1.4a5.06,5.06,0,0,1-3.46-1.34A9,9,0,1,1,11.53,2.26L12.78,0l1.38,3.5L15.5,6.9,14.36,7l-5.63.37L10.24,4.6a5.62,5.62,0,0,0-.92-.07,6.42,6.42,0,1,0,4.42,11.08,5.38,5.38,0,0,1-.31-1.78v-1a5.17,5.17,0,0,1,5.15-5.15H20A5.16,5.16,0,0,1,25.13,12.86Zm-3.59-.62a2.07,2.07,0,0,0-2.07-2.07h-.38a2,2,0,0,0-1,.27A2.06,2.06,0,0,0,17,12.24v2.32a2.25,2.25,0,0,0,.07.52,2.06,2.06,0,0,0,2,1.55h.38a2.07,2.07,0,0,0,2.07-2.07ZM68,4.23H63.69l-3,4.55-3-4.55H51.79l-2.06,9.45L47.34,4.23H43.53L41,14,38.86,4.23h-4l3.86,14.55h4.15l2.52-9.14,2.29,9.14H52l3-11.86L58.3,11.5l-5,7.28h4.19l3.1-4.65,2.86,4.65H68l-5.23-7.43ZM33.67,7.69a5.54,5.54,0,0,0-4.5,1.39V7.93H25.5V18.78h3.67V11.15s3.6-1.91,3.62.73v6.9h3.58v-6.9S36.46,8.08,33.67,7.69Z',
  width: 68.02,
  height: 20,
  params: ['#368ed8'],
})

export default conwxLogoIcon
