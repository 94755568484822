import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { history } from './reducers'
import createRootReducer from './reducers/index'
import thunk from 'redux-thunk'

const storeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'ConWX PowerForecastUI',
      })
    : compose

export function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer, // root reducer with router state
    preloadedState,
    compose(storeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))),
  )

  return store
}
