import { USERS } from '../types'

export function users(state = null, action) {
  const { type, payload } = action

  switch (type) {
    case USERS.LOADED:
      return payload
    case USERS.CREATED:
      return payload
    case USERS.DELETED:
      return payload
    case USERS.UPDATED:
      return payload
    default:
      return state
  }
}
