import axios from 'axios'
import { transformSelectedSetup } from '../api/utils'
import moment from 'moment'

const API_URL = `${process.env.REACT_APP_API_URL}`

const Metrics = {
  getMetrics(data) {
    return axios({
      method: 'post',
      url: `${API_URL}/metrics`,
      data,
    })
  },
  getSetupPlants(setup, dateRange) {
    const startDate =
      moment(dateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00'
    const endDate = moment(dateRange.endDate).format('YYYY-MM-DD') + ' 00:00:00'
    return axios({
      method: 'get',
      url: `${API_URL}/plants?setup_name=${transformSelectedSetup(
        setup,
      )}&start_date=${startDate}&end_date=${endDate}`,
    })
  },
  getSetupPercentiles(selectedSetup, selectedForecast) {
    return axios({
      method: 'get',
      url: `${API_URL}/get_percentiles?setup=${transformSelectedSetup(
        selectedSetup,
      )}&forecast_selection=${selectedForecast.name}`,
    })
  },
  getClientAggregationLevels(selectedSetup) {
    return axios({
      method: 'get',
      url: `${API_URL}/get_aggregation_level/${transformSelectedSetup(
        selectedSetup,
      )}`,
    })
  },
  getClientAggregationValues(selectedSetup, selectedAggregationLevel) {
    return axios({
      method: 'get',
      url: `${API_URL}/get_aggregation_values?client=${transformSelectedSetup(
        selectedSetup,
      )}&aggregation_level=${selectedAggregationLevel}`,
    })
  },
}

export default Metrics
