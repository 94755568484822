import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './redux/reducers'
import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { theme } from './styles/theme.js'
import AuthApp from './indexApp.js'
import './index.css'
import config from './config.json'
import { configureStore } from './redux/store.js'
const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <Helmet
              htmlAttributes={{ style: 'height: 100%;' }}
              bodyAttributes={{
                style: `background-color: ${theme.palette.generalUi.background}; margin: 0; height: 100%;`,
              }}
            >
              <title>{config.appTitle}</title>
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                rel="stylesheet"
              />
            </Helmet>
            <AuthApp />
          </MuiPickersUtilsProvider>
        </CookiesProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,

  document.getElementById('root'),
)
