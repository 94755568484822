import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { PrimaryButton } from '../components/restyled-mui/CustomButton'

const useStyles = makeStyles(theme => ({
  centered: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const PageNotFound = props => {
  const classes = useStyles(props)
  const { goToHomepage } = props

  return (
    <div className={classes.centered}>
      <PrimaryButton onClick={goToHomepage}>Frontpage</PrimaryButton>
    </div>
  )
}

export default PageNotFound
