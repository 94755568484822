import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Api from '../api'
import PortfolioHistoryPanel from '../panels/PortfolioHistoryPanel'
import PortfolioHistoryParameter from '../charts/PortfolioHistory/PortfolioHistoryParameter'
import PortfolioHistoryChart from '../charts/PortfolioHistory/PortfolioHistoryChart'
import NoSite from '../components/NoSite'
import { setSelectedUserSetup } from '../redux/actions/userSetups'

const useStyles = makeStyles(theme => ({
  paramContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  wraperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    width: '100%',
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const transformDate = dt => {
  const [datePart, timePart] = dt.split(' ')

  const [day, month, year] = datePart.split('/')
  const [hours, minutes] = timePart.split(':')

  const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}`)

  return date.toISOString().replace('T', ' ').slice(0, 16)
}

const PortfolioHistoryPage = props => {
  const classes = useStyles(props)
  const { container, selectedSetup, setSelectedUserSetup, userSetups } = props
  const [portfolioHistoryData, setPortfolioHistoryData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [nodataContent, setNodataContent] = useState({})
  // for disabling inputs in form
  const [trackDisableStatus, setTrackDisableStatus] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setTrackDisableStatus(true)
      try {
        const response = await Api.History.getHistory(selectedSetup)
        if (response.status === 204) {
          setPortfolioHistoryData(null)
          setTrackDisableStatus(false)
          setNodataContent({
            heading: `No data for selected setup: ${selectedSetup}`,
            body: '',
          })
        }
        if (response.status === 200) {
          setPortfolioHistoryData(response.data)
        }

        setLoading(false)
      } catch (error) {
        setPortfolioHistoryData(null)
        setTrackDisableStatus(false)
        if (error.response.status === 411) {
          setNodataContent({
            heading: `Error running query for selected setup: ${selectedSetup}`,
            body: '',
          })
        } else {
          setNodataContent({
            heading: `Error fetching data for selected setup: ${selectedSetup}`,
            body: '',
          })
        }
        console.error('Error fetching portfolio history data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (selectedSetup !== null) {
      fetchData()
    } else {
      setPortfolioHistoryData(null)
      setNodataContent({
        heading: 'No data',
        body: 'Select a setup to view portfolio history',
      })
    }
  }, [selectedSetup])

  const resultPanel = () => {
    return portfolioHistoryData !== null ? (
      <div className={classes.wraperContainer}>
        <div className={classes.paramContainer}>
          <PortfolioHistoryParameter
            label={'Last change to Portfolio'}
            value={transformDate(portfolioHistoryData['lastChanges'])}
            hasTooltip={true}
          />
          <PortfolioHistoryParameter
            label={'Installed capacity (KW)'}
            value={portfolioHistoryData['installedCapacity']}
            arrowValue={portfolioHistoryData['diffInstalledCapacity']}
          />
          <PortfolioHistoryParameter
            label={'Number of Plants'}
            value={portfolioHistoryData['numberOfPlants']}
            arrowValue={portfolioHistoryData['numberOfPlantsDiff']}
          />
          <PortfolioHistoryParameter
            label={'Number of BasedOn'}
            value={portfolioHistoryData['numberOfBasedOn']}
            arrowValue={portfolioHistoryData['numberOfBasedOnDiff']}
          />
        </div>
        <PortfolioHistoryChart
          chartData={portfolioHistoryData['history']}
          chartName={'chartPortfolioHistory'}
          selectedSetup={selectedSetup}
          setTrackDisableStatus={setTrackDisableStatus}
        ></PortfolioHistoryChart>
      </div>
    ) : (
      <div className={classes.centerItem}>
        <NoSite
          heading={nodataContent.heading}
          body={nodataContent.body}
        ></NoSite>
      </div>
    )
  }

  // If there is only 1 available setup, select it automatically
  if (userSetups.length === 1) {
    setSelectedUserSetup(userSetups[0])
  }

  // If there is only 1 setup, there is no point on having the drawer
  // so we hide it
  if (userSetups.length === 1) {
    if (!loading) {
      return resultPanel()
    }
    return (
      <div className={classes.centerItem}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <PortfolioHistoryPanel
        trackDisableStatus={trackDisableStatus}
        container={container}
        userSetups={userSetups}
      >
        {loading ? (
          <div className={classes.centerItem}>
            <CircularProgress />
          </div>
        ) : (
          resultPanel()
        )}
      </PortfolioHistoryPanel>
    </>
  )
}

const mapStateToProps = state => {
  return {
    selectedSetup: state.userSetups.selectedSetup,
    userSetups: state.userSetups.userSetups,
  }
}

const mapDispatchToProps = {
  setSelectedUserSetup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortfolioHistoryPage)
