import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  FormControl,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  LinearProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { CustomInputLabel, CustomTextField } from '../restyled-mui/CustomInputs'
import DateRangePicker from './DateRangePicker/DateRangePicker'
import HelpIcon from '@material-ui/icons/Help'
import { Remove as RemoveIcon, Add as AddIcon } from '@material-ui/icons'
import { Autocomplete } from '@mui/material'
import { PrimaryButton } from '../restyled-mui/CustomButton'
import { setSelectedUserSetup } from '../../redux/actions/userSetups'
import Api from '../../api'
import { transformSelectedSetup } from '../../api/utils'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
  },
  customSubtitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
  downloadDataButton: {
    width: '100%',
  },
  plantsDateErrorCss: {
    margin: '0px 5px',
    color: '#C63C3C',
  },
}))

const AnalysisForm = props => {
  const classes = useStyles(props)
  const {
    setAnalysisParameters,
    handleNoDataContentUpdate,
    selectedSetup,
    setSelectedUserSetup,
    userSetups,
    disableButton,
    setAnalysisData,
    loading,
  } = props

  const isEmptyOrContainsUndefined =
    userSetups.length === 0 || userSetups.some(x => x === undefined)

  const currentDate = new Date()

  const sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)

  const twoDaysForward = new Date()
  twoDaysForward.setDate(currentDate.getDate() + 2)
  const initialDateRange = { startDate: sevenDaysAgo, endDate: twoDaysForward }
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange)
  // const initialDateRangeRef = useRef(initialDateRange)

  // error for bad date formatting
  const [timeDisableBtn, setTimeDisableBtn] = useState(false)

  const [plantLoading, setPlantLoading] = useState(false)
  const [plantsCount, setPlantsCount] = useState(null)
  const [maxPlants, setMaxPlants] = useState(0)
  const [plantsError, setPlantsError] = useState(false)
  const [plantsErrorText, setPlantsErrorText] = useState('')
  const [textFieldKey, setTextFieldKey] = useState(0)

  const handleSetupListData = (_, newValue) => {
    setSelectedUserSetup(newValue)
    // setSelectedDateRange(initialDateRangeRef.current)
    if (newValue !== null) {
      handleNoDataContentUpdate({ heading: '', body: '' })
    } else {
      handleNoDataContentUpdate({
        heading: 'No setup selected',
        body: 'Select a setup and adjust parameters in order to start analysis',
      })
    }
  }

  const handleChangePlants = e => {
    setAnalysisData(null)
    const inputValue = parseInt(e.target.value, 10)
    if (!isNaN(inputValue) && inputValue >= 1 && inputValue <= maxPlants) {
      setPlantsCount(inputValue)
      setPlantsError(false)
    } else {
      setPlantsCount(null)
      setPlantsError(true)
    }
  }

  useEffect(() => {
    if (!selectedSetup) {
      setPlantsCount(null)
      setTextFieldKey(prevKey => prevKey + 1)
    }
  }, [selectedSetup])

  // get setup plants
  useEffect(() => {
    const fetchPlants = async () => {
      setPlantLoading(true)
      try {
        const response = await Api.Metrics.getSetupPlants(
          selectedSetup,
          selectedDateRange,
        )
        if (response.status === 200) {
        }
        const plantsCount = response.data.length
        if (plantsCount === 0) {
          setPlantsErrorText('No plants for selected period of time.')
          // handleNoDataContentUpdate({
          //   heading: 'Info:',
          //   body: 'Please change the DATE SELECTION to get the active plants.',
          // })
          handleNoDataContentUpdate({
            heading: '',
            body: '',
          })
        } else {
          setPlantsErrorText('')
          handleNoDataContentUpdate({ heading: '', body: '' })
        }

        setMaxPlants(plantsCount)
        setPlantsCount(plantsCount < 5 ? plantsCount : 5)
        setPlantLoading(false)
      } catch (error) {
        console.error(`Error fetching plants for ${selectedSetup}:`, error)
      } finally {
        setPlantLoading(false)
      }
    }
    if (selectedSetup !== null) {
      fetchPlants()
    } else {
      setPlantsCount(null)
    }
  }, [selectedSetup, selectedDateRange])

  const startAnalysis = () => {
    setAnalysisParameters({
      client: transformSelectedSetup(selectedSetup),
      forecast_type: 'ID',
      start_date: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(selectedDateRange.endDate).format('YYYY-MM-DD'),
      issue_time: 600,
      plant_number: plantsCount,
    })
  }

  const disableDown = plantsCount - 1 < 1 || plantsError
  const disableUp = plantsCount >= maxPlants || plantsError || !selectedSetup

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography
              variant="h4"
              className={clsx(classes.formSubtitle, classes.customSubtitle)}
            >
              Setup selection
            </Typography>
            {!isEmptyOrContainsUndefined ? (
              <>
                <div className={classes.tooltipContainer}>
                  <CustomInputLabel>Setup Name</CustomInputLabel>
                  <Tooltip
                    title="You can search for a value by typing in the box!"
                    placement="top"
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <span>
                      <IconButton
                        style={{ padding: '0' }}
                        aria-label="info"
                        disableTouchRipple
                      >
                        <HelpIcon
                          fontSize="small"
                          className={classes.helpIcon}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>

                <FormControl fullWidth variant="outlined" size="small">
                  <Autocomplete
                    disabled={loading}
                    variant="outlined"
                    size="small"
                    options={userSetups}
                    value={selectedSetup}
                    autoHighlight
                    onChange={handleSetupListData}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { padding: '1px 5px' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </>
            ) : (
              <CustomInputLabel>
                No available setup for selection or error occurred.
              </CustomInputLabel>
            )}
          </Grid>
          {!isEmptyOrContainsUndefined && (
            <>
              <Grid item md={12}>
                <Typography
                  variant="h4"
                  className={clsx(classes.formSubtitle, classes.customSubtitle)}
                >
                  Date selection
                </Typography>

                <Grid container spacing={2} style={{ paddingBottom: '15px' }}>
                  <DateRangePicker
                    trackDisableStatus={loading}
                    initialDateRange={selectedDateRange}
                    onDateRangeChange={dateRange => {
                      setSelectedDateRange(dateRange)
                      setAnalysisData(null)
                    }}
                    onErrorChange={setTimeDisableBtn}
                  />
                  <CustomInputLabel className={classes.plantsDateErrorCss}>
                    {plantsErrorText}
                  </CustomInputLabel>
                </Grid>
              </Grid>

              {plantsCount !== 0 && (
                <Grid item md={12}>
                  <Typography
                    variant="h4"
                    className={clsx(
                      classes.formSubtitle,
                      classes.customSubtitle,
                    )}
                  >
                    Plants
                  </Typography>
                  <Grid item md={12}>
                    <CustomInputLabel>
                      Number of plants (sorted by capacity)
                    </CustomInputLabel>
                    {plantLoading ? (
                      <LinearProgress style={{ marginTop: '20px' }} />
                    ) : (
                      <TextField
                        key={textFieldKey}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        size="small"
                        value={plantsCount}
                        disabled={!selectedSetup || loading}
                        onChange={handleChangePlants}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                aria-label="decrease"
                                onClick={() => {
                                  setPlantsCount(prevValue => {
                                    const newValue = prevValue - 1
                                    return newValue < 0 ? prevValue : newValue
                                  })
                                  setAnalysisData(null)
                                }}
                                disabled={disableDown || loading}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                aria-label="increase"
                                onClick={() => {
                                  setPlantsCount(prevValue => {
                                    const newVal = prevValue + 1
                                    return newVal > maxPlants
                                      ? prevValue
                                      : newVal
                                  })
                                  setAnalysisData(null)
                                }}
                                disabled={disableUp || loading}
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          plantsError
                            ? `Maximum number of plants is: ${maxPlants}`
                            : ''
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              {selectedSetup && !plantLoading && (
                <Grid item md={12}>
                  <PrimaryButton
                    style={{
                      background:
                        (disableButton || plantsError || timeDisableBtn) &&
                        '#ccc',
                    }}
                    className={classes.downloadDataButton}
                    onClick={startAnalysis}
                    disabled={disableButton || plantsError || timeDisableBtn}
                  >
                    RUN
                  </PrimaryButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  selectedSetup: state.userSetups.selectedSetup,
  userSetups: state.userSetups.userSetups,
})

const mapDispatchToProps = {
  setSelectedUserSetup,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisForm)
