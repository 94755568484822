import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

const Users = {
  getUsers() {
    return axios({
      method: 'get',
      url: `${API_URL}/get_all_users`,
    })
  },

  createUser(user) {
    return axios({
      method: 'post',
      url: `${API_URL}/add_user`,
      data: user,
    })
  },

  deleteUser(id) {
    return axios({
      method: 'delete',
      url: `${API_URL}/delete_user/${id}`,
    })
  },

  updateUser(newUser) {
    return axios({
      method: 'put',
      url: `${API_URL}/edit_user`,
      data: newUser,
    })
  },
}

export default Users
