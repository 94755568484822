import { METRICS } from '../types'

export const setSelectedSetupForecast = forecast => {
  return {
    type: METRICS.FORECAST,
    payload: forecast,
  }
}

export const setSelectedSetupPlant = plant => {
  return {
    type: METRICS.PLANT,
    payload: plant,
  }
}

export const setSelectedSetupForecastType = ftype => {
  return {
    type: METRICS.FORECAST_TYPE,
    payload: ftype,
  }
}

export const setIssueTime = time => {
  return {
    type: METRICS.ISSUE_TIME,
    payload: time,
  }
}

export const setSelectedPercentile = percentiles => {
  return {
    type: METRICS.PERCENTILES,
    payload: percentiles,
  }
}

export const setSelectedDateRange = dateRange => {
  return {
    type: METRICS.DATE,
    payload: dateRange,
  }
}

export const setSelectedAggregationLevel = aggrLvl => {
  return {
    type: METRICS.AGGREGATION_LEVEL,
    payload: aggrLvl,
  }
}

export const setSelectedAggregationValue = aggValue => {
  return {
    type: METRICS.AGGREGATION_VALUE,
    payload: aggValue,
  }
}

export const setLeadTime = ltime => {
  return {
    type: METRICS.LEADTIME,
    payload: ltime,
  }
}
