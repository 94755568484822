import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ConfirmationDialog from '../confirmation-dialog/Confirmation'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getSettingsStyles } from '../../styles/settingsStyles'
import { getSetups, deleteSetup, updateSetup } from '../../redux/actions/setups'
import {
  CustomInputLabel,
  CustomTextField,
} from '../../components/restyled-mui/CustomInputs'
import { Typography, Grid, CircularProgress } from '@material-ui/core'
import { CustomButton } from '../../components/restyled-mui/CustomButton'
import Navigation from '../settings-navigation/Navigation'

const useStyles = makeStyles(theme => ({
  ...getSettingsStyles(theme),
  mainContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '50px 0 50px 0',
  },
}))

const Setup = props => {
  const classes = useStyles(props)
  const theme = useTheme()
  const {
    history,
    setups,
    getSetups,
    deleteSetup,
    updateSetup,
    creating,
    apiLoading,
    apiStatusOk,
    match,
  } = props

  const initialConfirmDialog = {
    open: false,
    confirmCallback: null,
    cancelCallback: null,
    title: '',
    content: '',
    color: '',
  }
  const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog)
  const [loading, setLoading] = useState(true)
  const [setup, setSetup] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!setups) {
      getSetups()
    }
  }, [setups, getSetups])

  useEffect(() => {
    if (!apiLoading && apiStatusOk && submitted) {
      history.push(`/settings/setups`)
      setSubmitted(false)
    }
  }, [apiLoading, submitted, apiStatusOk])

  useEffect(() => {
    if (setups) {
      if (creating) {
        setSetup({
          name: '',
          alias: '',
        })
      } else {
        const setup = setups.find(s => s.id === parseInt(match.params.id))
        if (setup) {
          setSetup(setup)
        }
      }
    }
  }, [setups, creating, match.params.id])

  useEffect(() => {
    if (setups) {
      setLoading(false)
    }
  })

  const handleTextInputChange = state => e => {
    const newVal = e.target.value

    setSetup({
      ...setup,
      [state]: newVal,
    })
  }

  const handleSubmit = () => {
    updateSetup(setup)
    setSubmitted(true)
  }

  const handleCancel = () => {
    history.push(`/settings/setups`)
  }

  const handleDelete = () => {
    setConfirmDialog({
      open: true,
      confirmCallback: () => {
        deleteTheClient(setup.id)
        setConfirmDialog(initialConfirmDialog)
      },
      cancelCallback: () => setConfirmDialog(initialConfirmDialog),
      title: 'Delete Setup',
      content: (
        <Typography>
          Are you sure you want to delete <b>{setup.name}</b>?
        </Typography>
      ),
      color: theme.palette.error.main,
      type: 'warning',
    })
  }

  const deleteTheClient = id => {
    deleteSetup(id)
    setSubmitted(true)
  }

  const renderForm = () => {
    return (
      <Grid container spacing={4} className={classes.formContainer}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h4" className={classes.formSubtitle}>
              Details
            </Typography>
            <CustomInputLabel>Setup Name</CustomInputLabel>
            <CustomTextField
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              type="text"
              onChange={handleTextInputChange('name')}
              value={setup.name}
            />
            <CustomInputLabel>Alias</CustomInputLabel>
            <CustomTextField
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              type="text"
              onChange={handleTextInputChange('alias')}
              value={setup.alias}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        {!creating && (
          <CustomButton
            onClick={handleDelete}
            disabled={apiLoading}
            variant="contained"
            disableElevation
            className={classes.deleteButton}
          >
            Delete
          </CustomButton>
        )}
        <CustomButton
          onClick={handleCancel}
          disabled={apiLoading}
          variant="contained"
          disableElevation
          className={classes.cancelButton}
        >
          Cancel
        </CustomButton>

        <CustomButton
          onClick={handleSubmit}
          disabled={apiLoading}
          variant="contained"
          disableElevation
          color="primary"
          className={classes.submitButton}
        >
          {apiLoading ? <CircularProgress size={14} /> : 'Submit'}
        </CustomButton>
      </div>
    )
  }

  return (
    <Navigation history={history}>
      {loading ? (
        <div style={{ margin: '25% 50%' }}>
          <CircularProgress />
        </div>
      ) : setup ? (
        <>
          <ConfirmationDialog {...confirmDialog} />
          <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
              <Typography variant="h2" className={classes.title}>
                {setup.name}
              </Typography>
              {renderActionButtons()}
            </div>
            {renderForm()}
          </div>
        </>
      ) : (
        <Typography variant="h2">Setup not found</Typography>
      )}
    </Navigation>
  )
}

const mapStateToProps = state => {
  return {
    setups: state.setups,
    apiLoading: state.status.loading,
    apiStatusOk: state.status.snackbar.severity,
  }
}

export default connect(mapStateToProps, {
  getSetups,
  deleteSetup,
  updateSetup,
})(Setup)
