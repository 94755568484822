import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import status from './status'
import { user } from './user'
import { users } from './users'
import { USER } from '../types'
import userPermissions from './permissions'
import userSetups from './userSetups'
import { setups } from './setups'
import setup from './setup'
import metrics from './metrics'

const createRootReducer = (state, action) => {
  if (action.type === USER.LOGOUT) {
    const { router } = state
    state = { router }
  }
  return appReducer(state, action)
}

export const history = createBrowserHistory()

const appReducer = combineReducers({
  router: connectRouter(history),
  status,
  user,
  users,
  userPermissions,
  userSetups,
  setups,
  setup,
  metrics,
})

export default createRootReducer
