import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CustomCheckbox } from '../../components/restyled-mui/CustomInputs'

export const CustomList = withStyles(_theme => ({
  root: {
    padding: 0,
  },
}))(List)
export const CustomListItem = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 4),
  },
  container: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.table.cellBg,
    },
    borderBottom: `1px solid ${theme.palette.table.cellBorder}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))(ListItem)

export const CustomListItemText = withStyles(_theme => ({
  root: {},
}))(ListItemText)

export const CustomListItemSecondaryAction = withStyles(_theme => ({
  root: {},
}))(ListItemSecondaryAction)

const useStyles = makeStyles(theme => ({
  listContainer: {
    overflowY: 'auto',
    border: `1px solid ${theme.palette.table.cellBorder}`,
    marginTop: 26,
    marginBottom: 20,
    minHeight: 'fit-content',
    maxHeight: '325px',
  },
}))

const SetupsSelections = props => {
  const classes = useStyles(props)
  const { allSetups, user, setUser, searchSetupName } = props

  const [syncPermissions, setSyncModulePermissions] = useState([])
  const userSetups = user.setups

  useEffect(() => {
    const syncLists = allSetups.map(item => ({
      name: item.name,
      id: item.id,
      checked: userSetups.includes(item.name),
    }))
    setSyncModulePermissions(syncLists)
  }, [allSetups])

  const toggleAllSetups = e => {
    const isChecked = e.target.checked

    const newSyncList = syncPermissions.map(permission => ({
      ...permission,
      checked: isChecked,
    }))

    setSyncModulePermissions(newSyncList)

    if (isChecked) {
      const setupsAll = syncPermissions.map(setup => setup.id)
      setUser({ ...user, setups: setupsAll })
    } else {
      setUser({ ...user, setups: [] })
    }
  }

  const handleCheckboxClick = id => {
    const updatedPermissions = syncPermissions.map(permission => {
      if (permission.id === id) {
        return { ...permission, checked: !permission.checked }
      }
      return permission
    })

    setSyncModulePermissions(updatedPermissions)

    const updatedModulePermissions = updatedPermissions
      .filter(permission => permission.checked)
      .map(setup => setup.id)
    setUser({ ...user, setups: updatedModulePermissions })
  }

  const filteredSetups = syncPermissions.filter(item =>
    item.name.toLowerCase().includes(searchSetupName.toLowerCase()),
  )

  return (
    <div className={classes.listContainer}>
      <div className="MuiListItem-container">
        <CustomListItemText
          style={{ padding: '10px 16px' }}
          primary="Select All"
        />
        <CustomListItemSecondaryAction>
          <CustomCheckbox
            indeterminate={
              userSetups.length > 0 &&
              filteredSetups.some(permission => !permission.checked)
            }
            checked={
              filteredSetups.length > 0 &&
              filteredSetups.every(permission => permission.checked)
            }
            onClick={toggleAllSetups}
          ></CustomCheckbox>
        </CustomListItemSecondaryAction>
      </div>

      <CustomList>
        {filteredSetups.map(item => (
          <CustomListItem key={item.id}>
            <CustomListItemText primary={item.name} />
            <CustomListItemSecondaryAction>
              <CustomCheckbox
                checked={item.checked}
                onClick={() => handleCheckboxClick(item.id)}
              />
            </CustomListItemSecondaryAction>
          </CustomListItem>
        ))}
      </CustomList>
    </div>
  )
}

export default SetupsSelections
