import React from 'react'

const WikiPage = props => {
  return (
    <div style={{ margin: '40% 40%' }}>
      <h1>Wiki Page</h1>
    </div>
  )
}

export default WikiPage
