import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'

const sitesListWidth = 345
const tabletSidebarWidth = 48

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
  },
  title: {
    margin: '32px 24px 6px 24px',
  },
  selected: {
    backgroundColor: theme.palette.secondMenuItem.selectedBackground,
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(4, 8, 0, 8),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 800px',
    '@media (max-width: 960px)': {
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
}))

const Navigation = props => {
  const classes = useStyles()
  const { history } = props

  const pages = [
    {
      path: `/settings/users`,
      name: 'Users',
    },
    // {
    //   path: `/settings/setups`,
    //   name: 'Setups',
    // },
  ]

  const navigateTo = path => {
    history.push(path)
  }

  return (
    <>
      <Drawer
        open={true}
        variant={'permanent'}
        anchor={'left'}
        ModalProps={{
          keepMounted: true,
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: { position: 'absolute' },
          className: classes.drawerPaper,
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        classes={{ paper: classes.drawer }}
      >
        <Typography variant="h6" className={classes.title}>
          Settings
        </Typography>
        <List className={classes.subMenu}>
          {pages.map(page => {
            const { path, name } = page
            const selected = Boolean(history.location.pathname.includes(path))
            return (
              <ListItem
                key={path}
                selected={selected}
                button={!selected}
                className={selected ? classes.selected : classes.row}
                onClick={() => navigateTo(path)}
              >
                <ListItemText primary={name} className={classes.listItemText} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <div className={classes.childrenContainer}>{props.children}</div>
    </>
  )
}

export default withRouter(Navigation)
