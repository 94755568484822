export const STATUS = {
  FETCHING: 'STATUS_FETCHING',
  SUCCESS: 'STATUS_SUCCESS',
  ERROR: 'STATUS_ERROR',
  RESET: 'STATUS_RESET',
}

export const USER = {
  LOGOUT: 'USER_LOGOUT',
  LOGIN: 'USER_LOGIN',
  LOADED: 'USER_LOADED',
}
export const USERS = {
  LOADED: 'USERS_LOADED',
  UPDATED: 'USER_UPDATED',
  CREATED: 'USER_CREATED',
  DELETED: 'USER_DELETED',
}

export const SET_USER = {
  SETUP: 'SETUP',
  SETUPS: 'SETUPS',
}

export const USER_PERMISSIONS = 'USER_PERMISSIONS'

export const SETUPS = {
  LOADED: 'SETUPS_LOADED',
  UPDATED: 'SETUP_UPDATED',
  CREATED: 'SETUP_CREATED',
  DELETED: 'SETUP_DELETED',
}

export const SETUP = {
  LOGOUT: 'SETUP_LOGOUT',
  LOGIN: 'SETUP_LOGIN',
  LOADED: 'SETUP_LOADED',
}

export const METRICS = {
  FORECAST: 'FORECAST',
  PLANT: 'PLANT',
  FORECAST_TYPE: 'FORECAST_TYPE',
  ISSUE_TIME: 'ISSUE_TIME',
  PERCENTILES: 'PERCENTILES',
  DATE: 'DATE',
  AGGREGATION_LEVEL: 'AGGREGATION_LEVEL',
  AGGREGATION_VALUE: 'AGGREGATION_VALUE',
  LEADTIME: 'LEADTIME',
}
