import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Navigation from '../settings-navigation/Navigation'
import {
  CustomInputLabel,
  CustomTextField,
} from '../../components/restyled-mui/CustomInputs'
import {
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  FormControl,
  Tooltip,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { Autocomplete } from '@mui/material'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getSettingsStyles } from '../../styles/settingsStyles'
import { CustomButton } from '../../components/restyled-mui/CustomButton'
import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
} from '../../redux/actions/users'
import PermissionsSelection from './PermissionsSelection'
import SetupsSelections from './SetupsSelections'
import ConfirmationDialog from '../confirmation-dialog/Confirmation'
import ClearIcon from '@mui/icons-material/Clear'
import getModulePermissions from '../../utils/moduleConfig'
import Api from '../../api'
import timezones from '../../../src/utils/timezones'

const useStyles = makeStyles(theme => ({
  ...getSettingsStyles(theme),
  mainContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '50px 0 50px 0',
  },
  setupsGrid: {
    paddingTop: '36px',
    marginLeft: '15%',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
}))

const User = props => {
  const classes = useStyles(props)
  const theme = useTheme()
  const {
    history,
    loadedUser,
    users,
    getUsers,
    createUser,
    deleteUser,
    updateUser,
    creating,
    match,
    apiLoading,
    apiStatusOk,
  } = props
  const initialConfirmDialog = {
    open: false,
    confirmCallback: null,
    cancelCallback: null,
    title: '',
    content: '',
    color: '',
  }
  const units = ['kW', 'kWh', 'MW', 'MWh']
  const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [allModulePermissions, setAllModulePremissions] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [allSetups, setAllSetups] = useState(null)
  const [searchSetupName, setSearchSetupName] = useState('')
  const [formState, setFormState] = useState({
    username: '',
    usernameError: '',
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    timezone: null,
    timezoneError: '',
    unit: '',
    unitError: '',
  })

  useEffect(() => {
    const fetchAllSetups = async () => {
      try {
        const response = await Api.Setups.getSetups()
        setAllSetups(response.data.setups)
      } catch (error) {
        console.error('Error fetching client setups:', error)
      }
    }

    fetchAllSetups()
  }, [loadedUser])

  useEffect(() => {
    if (!users) {
      getUsers()
    }
  }, [users, getUsers])

  useEffect(() => {
    if (!allModulePermissions) {
      setAllModulePremissions(getModulePermissions())
    }
  }, [allModulePermissions])

  useEffect(() => {
    if (users) {
      if (creating) {
        setUser({
          name: '',
          email: '',
          password: '',
          permissions: [],
          setups: [],
        })
        setLoading(false)
      } else {
        const user = users.find(u => u.id === parseInt(match.params.id))
        if (user) {
          setFormState({
            username: user.name,
            email: user.email,
            password: '',
            timezone: user.timezone,
            unit: user.unit,
          })
          setUser(user)
          setLoading(false)
        }
      }
    }
  }, [users, creating, match.params.id, allSetups])

  useEffect(() => {
    if (!apiLoading && apiStatusOk && submitted) {
      history.push(`/settings/users`)
      setSubmitted(false)
    }
  }, [apiLoading, submitted, apiStatusOk, history])

  const handleTextInputChange = state => e => {
    const newVal = e.target.value

    setFormState({
      ...formState,
      [state]: newVal,
    })
  }

  const handleCreate = () => {
    if (validateForm({ create: true })) {
      const updUser = {
        ...user,
        name: formState.username,
        email: formState.email,
        password: formState.password,
        timezone: formState.timezone,
        unit: formState.unit,
      }
      createUser(updUser)
      setSubmitted(true)
    }
  }

  const handleSubmit = () => {
    if (validateForm({ create: false })) {
      const updUser = {
        ...user,
        email: formState.email,
        timezone: formState.timezone,
        unit: formState.unit,
      }
      if (formState.password.trim().length !== 0) {
        updUser.password = formState.password
      }
      updateUser(updUser)
      setSubmitted(true)
    }
  }

  const handleCancel = () => {
    history.push(`/settings/users`)
  }

  const handleDelete = () => {
    setConfirmDialog({
      open: true,
      confirmCallback: () => {
        deleteTheUser(user.id)
        setConfirmDialog(initialConfirmDialog)
      },
      cancelCallback: () => setConfirmDialog(initialConfirmDialog),
      title: 'Delete User',
      content: (
        <Typography>
          Are you sure you want to delete <b>{user.name}</b>?
        </Typography>
      ),
      color: theme.palette.error.main,
      type: 'warning',
    })
  }

  const deleteTheUser = id => {
    deleteUser(id)
    setSubmitted(true)
  }

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validateForm = options => {
    const { create } = options
    const { username, email, password, timezone, unit } = formState
    let errors = {
      usernameError: !username.trim() ? 'Username is required' : '',
      emailError: !email
        ? 'Email is required'
        : !validateEmail(email.trim())
        ? 'Invalid email format'
        : '',

      timezoneError: !timezone ? 'Timezone is required' : '',
      unitError: !unit ? 'Unit is required' : '',
    }
    if (create) {
      errors = {
        ...errors,
        passwordError: !password.trim() ? 'Password is required' : '',
      }
    }
    setFormState(prevFormState => ({ ...prevFormState, ...errors }))
    return Object.values(errors).every(error => !error)
  }

  const renderForm = () => {
    return (
      <Grid container spacing={4} className={classes.formContainer}>
        <Grid item xs={12} md={12} lg={12} style={{ display: 'flex' }}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h4" className={classes.formSubtitle}>
              Details
            </Typography>
            {creating && (
              <>
                <CustomInputLabel>Username</CustomInputLabel>
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  type="text"
                  onChange={handleTextInputChange('username')}
                  value={formState.username}
                  error={Boolean(formState.usernameError)}
                  helperText={formState.usernameError}
                />
              </>
            )}
            <>
              <CustomInputLabel>Email</CustomInputLabel>
              <CustomTextField
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                type="text"
                onChange={handleTextInputChange('email')}
                value={formState.email}
                error={Boolean(formState.emailError)}
                helperText={formState.emailError}
              />
            </>

            <>
              {creating ? (
                <CustomInputLabel>Password</CustomInputLabel>
              ) : (
                <div className={classes.tooltipContainer}>
                  <CustomInputLabel>Change password</CustomInputLabel>
                  <Tooltip
                    title="The password will remain unchanged if the field is left empty."
                    placement="top"
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <span>
                      <IconButton
                        style={{ padding: '0' }}
                        aria-label="info"
                        disableTouchRipple
                      >
                        <HelpIcon
                          fontSize="small"
                          className={classes.helpIcon}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
              <CustomTextField
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                type="password"
                autoComplete="new-password"
                onChange={handleTextInputChange('password')}
                value={formState.password}
                error={Boolean(formState.passwordError)}
                helperText={formState.passwordError}
              />
            </>

            <CustomInputLabel>Permissions</CustomInputLabel>
            {allModulePermissions ? (
              <PermissionsSelection
                allPermissions={allModulePermissions}
                user={user}
                setUser={setUser}
              ></PermissionsSelection>
            ) : (
              <div style={{ margin: '25% 45%' }}>
                <CircularProgress />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4} className={classes.setupsGrid}>
            <>
              <CustomInputLabel>Timezone</CustomInputLabel>
              <FormControl fullWidth variant="outlined" size="small">
                <Autocomplete
                  variant="outlined"
                  size="small"
                  options={timezones}
                  value={formState.timezone}
                  autoHighlight
                  onChange={(_, newTimezone) => {
                    setFormState({ ...formState, timezone: newTimezone })
                  }}
                  renderInput={params => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { padding: '1px 5px' },
                      }}
                      error={Boolean(formState.timezoneError)}
                      helperText={formState.timezoneError}
                    />
                  )}
                />
              </FormControl>
            </>

            <>
              <CustomInputLabel>Unit</CustomInputLabel>
              <FormControl fullWidth variant="outlined" size="small">
                <Autocomplete
                  variant="outlined"
                  size="small"
                  options={units}
                  value={formState.unit}
                  autoHighlight
                  onChange={(_, newUnit) => {
                    setFormState({ ...formState, unit: newUnit })
                  }}
                  renderInput={params => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { padding: '1px 5px' },
                      }}
                      error={Boolean(formState.unitError)}
                      helperText={formState.unitError}
                    />
                  )}
                />
              </FormControl>
            </>

            <CustomInputLabel>Setups</CustomInputLabel>
            <CustomTextField
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              type="setups"
              placeholder="Search for setup..."
              autoComplete="on"
              onChange={e => setSearchSetupName(e.target.value)}
              value={searchSetupName}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchSetupName('')
                    }}
                    aria-label="Clear All"
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            {allSetups ? (
              <SetupsSelections
                allSetups={allSetups}
                user={user}
                setUser={setUser}
                searchSetupName={searchSetupName}
              ></SetupsSelections>
            ) : (
              <div style={{ margin: '25% 45%' }}>
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        {!creating && (
          <CustomButton
            onClick={handleDelete}
            disabled={apiLoading}
            variant="contained"
            disableElevation
            className={classes.deleteButton}
          >
            Delete
          </CustomButton>
        )}
        <CustomButton
          onClick={handleCancel}
          disabled={apiLoading}
          variant="contained"
          disableElevation
          className={classes.cancelButton}
        >
          Cancel
        </CustomButton>

        <CustomButton
          onClick={creating ? handleCreate : handleSubmit}
          disabled={apiLoading}
          variant="contained"
          disableElevation
          color="primary"
          className={classes.submitButton}
        >
          {apiLoading ? <CircularProgress size={14} /> : 'Submit'}
        </CustomButton>
      </div>
    )
  }

  return (
    <Navigation history={history}>
      {loading ? (
        <div style={{ margin: '25% 50%' }}>
          <CircularProgress />
        </div>
      ) : user ? (
        <>
          <ConfirmationDialog {...confirmDialog} />
          <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
              <Typography variant="h2" className={classes.title}>
                {creating ? 'New User' : user.name}
              </Typography>
              {renderActionButtons()}
            </div>
            {renderForm()}
          </div>
        </>
      ) : (
        <Typography variant="h2">User not found</Typography>
      )}
    </Navigation>
  )
}

const mapStateToProps = state => {
  return {
    loadedUser: state.user,
    users: state.users,
    apiLoading: state.status.loading,
    apiStatusOk: state.status.snackbar.severity,
    userPermissions: state.userPermissions.permissions,
  }
}

export default connect(mapStateToProps, {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
})(User)
