import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import App from './App'
import Login from './pages/Login'
import { useHistory } from 'react-router-dom'
import Api from './api'
import { configureApi } from './api/config'
import { loggedUser, logout } from './redux/actions/user'
import { userPermissions } from './redux/actions/permissions'
import { userSetups } from './redux/actions/userSetups'
import CenteredLoading from './components/CenteredLoading'
import {
  loadStateFromLocalStorage,
  clearStateFromLocalStorage,
} from './redux/utilsLocalStorage'

const IndexApp = props => {
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies(['authToken'])
  const [errorMessage, setErrorMessage] = useState('')
  const [authenticated, setAuthenticated] = useState(null)
  const [loading, setLoading] = useState(false)
  const [failedLoginCounter, setFailedLoginCounter] = useState(0)
  const history = useHistory()

  useEffect(() => {
    if (cookies.authToken) {
      configureApi(cookies.authToken.slice(0, 30) + cookies.authToken.slice(31))
      const usrPerm = loadStateFromLocalStorage('permissions')
      const usrSetups = loadStateFromLocalStorage('setups')

      dispatch(userPermissions(usrPerm))
      dispatch(userSetups(usrSetups, usrPerm))
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [cookies.authToken])

  const authenticate = async (formData, rememberLogin) => {
    try {
      setLoading(true)
      const responseAuth = await Api.authenticateUser(formData)
      const token = responseAuth.data.access_token

      if (rememberLogin) {
        saveToken(token)
      }
      configureApi(token)

      const responseUser = {
        username: formData.name,
        password: formData.password,
        permissions: responseAuth.data.permissions,
        setups: responseAuth.data.setups,
        userId: responseAuth.data.user_id,
        timezone: responseAuth.data.timezone
          ? responseAuth.data.timezone
          : 'Etc/UTC',
        unit: responseAuth.data.unit,
      }

      dispatch(loggedUser(responseUser))
      dispatch(userPermissions(responseUser.permissions))
      dispatch(userSetups(responseUser.setups, responseUser.permissions))

      setAuthenticated(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (failedLoginCounter < 2) {
        setErrorMessage('Incorrect username and/or password. Please try again.')
        setFailedLoginCounter(failedLoginCounter + 1)
      } else {
        setErrorMessage(
          'Incorrect username and/or password. Please try again or contact your administrator to reset your password.',
        )
      }
      setAuthenticated(false)
      logoutUser()
    }
  }

  const saveToken = useCallback(
    token => {
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000)
      setCookie('authToken', token.slice(0, 30) + 's' + token.slice(30), {
        path: '/',
        expires: expirationDate,
      })
    },
    [setCookie],
  )

  const logoutUser = useCallback(() => {
    removeCookie('authToken', { path: '/' })
    clearStateFromLocalStorage()
    setAuthenticated(false)
    dispatch(logout())
    history.push('/')
  }, [removeCookie, dispatch])

  if (authenticated) {
    return <App logout={logoutUser} />
  } else if (!authenticated) {
    return (
      <Login
        loading={loading}
        onSubmitUser={authenticate}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    )
  } else {
    return <CenteredLoading />
  }
}

export default IndexApp
