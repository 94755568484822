import { SETUPS } from '../types'

export function setups(state = null, action) {
  const { type, payload } = action

  switch (type) {
    case SETUPS.LOADED:
      return payload
    case SETUPS.UPDATED:
      return payload
    default:
      return state
  }
}
