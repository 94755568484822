import { SET_USER } from '../types'
import { saveStateToLocalStorage } from '../utilsLocalStorage'

export const setSelectedUserSetup = setup => ({
  type: SET_USER.SETUP,
  payload: setup,
})

export const userSetups = (setups, permissions) => {
  const key = permissions.includes('settings') ? 'name' : 'alias'
  const transformedSetups = setups.map(el => el[key])
  return dispatch => {
    dispatch({
      type: SET_USER.SETUPS,
      payload: transformedSetups,
    })
    saveStateToLocalStorage(setups, 'setups')
  }
}
