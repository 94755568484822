import { USERS } from '../types'
import { handleError } from '../../utils/error'
import { STATUS } from '../types'
import { changeStatus } from './status'
import Api from '../../api'
import { loadStateFromLocalStorage } from '../utilsLocalStorage'

const transformSetupsId2Name = setupsArr => {
  const type = typeof setupsArr[0]

  if (type === 'number') {
    const setupsLocalStorage = loadStateFromLocalStorage('setups')
    return setupsLocalStorage
      .filter(setup => setupsArr.includes(setup.id))
      .map(setup => setup.name)
  } else {
    return setupsArr
  }
}

const transformSetupsName2Id = setupsToTransform => {
  const type = typeof setupsToTransform[0]

  if (type === 'string') {
    const setupsLocalStorage = loadStateFromLocalStorage('setups')
    return setupsLocalStorage
      .filter(setup => setupsToTransform.includes(setup.name))
      .map(setup => setup.id)
  } else {
    return setupsToTransform
  }
}

export const getUsers = () => {
  return dispatch => {
    return Api.Users.getUsers()
      .then(res => {
        dispatch({ type: USERS.LOADED, payload: res.data.users })
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const createUser = newUser => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))
    return Api.Users.createUser(newUser)
      .then(res => {
        const transSetups = transformSetupsId2Name(newUser.setups)
        const newUserWithId = {
          ...newUser,
          id: res.data.user_id,
          setups: transSetups,
        }

        const updatedUsers = [...getState().users, newUserWithId]

        dispatch({ type: USERS.CREATED, payload: updatedUsers })
        dispatch(changeStatus(STATUS.SUCCESS, res.data.msg))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const deleteUser = id => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))
    return Api.Users.deleteUser(id)
      .then(res => {
        const updatedUsers = getState().users.filter(user => user.id !== id)

        dispatch({ type: USERS.DELETED, payload: updatedUsers })
        dispatch(changeStatus(STATUS.SUCCESS, res.data.msg))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const updateUser = updatedUser => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))

    const transformedSetups = transformSetupsName2Id(updatedUser.setups)

    const partialUser = {
      user_id: updatedUser.id,
      setups: transformedSetups,
      permissions: updatedUser.permissions,
      email: updatedUser.email,
      password: updatedUser.password,
      timezone: updatedUser.timezone,
      unit: updatedUser.unit,
    }

    return Api.Users.updateUser(partialUser)
      .then(res => {
        const transSetups = transformSetupsId2Name(updatedUser.setups)
        const newUpdUser = {
          ...updatedUser,
          setups: transSetups,
        }

        const updatedUsers = getState().users.map(user => {
          if (user.id === newUpdUser.id) {
            return { ...user, ...newUpdUser }
          }
          return user
        })

        dispatch({ type: USERS.UPDATED, payload: updatedUsers })
        dispatch(changeStatus(STATUS.SUCCESS, res.data.msg))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}
