import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { getSettingsStyles } from '../../styles/settingsStyles'
import Navigation from '../settings-navigation/Navigation'
import { DataGrid } from '@mui/x-data-grid'
import { CircularProgress } from '@material-ui/core'
import { getSetups } from '../../redux/actions/setups'

const createColumns = key => {
  return [
    {
      field: key,
      headerName: 'NAME',
      flex: 3,
      headerClassName: 'super-app-theme--header',
    },
  ]
}

const useStyles = makeStyles(theme => ({
  ...getSettingsStyles(theme),
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '50px 0 20px 0',
  },

  container: {
    textAlign: 'center',
    width: '90%',
    margin: '0 auto',
  },
}))

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
}

const Setups = props => {
  const classes = useStyles(props)
  const location = useLocation()
  const { history, setups, getSetups, userPermissions } = props
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const isAdmin = userPermissions.includes('settings')
    if (isAdmin) {
      setColumns(createColumns('name'))
    } else {
      setColumns(createColumns('alias'))
    }
  }, [userPermissions])

  useEffect(() => {
    if (!setups) {
      getSetups()
    }
  }, [setups, getSetups])

  const editSetup = params => {
    const setup = setups.find(setup => setup.id === params.row.id)
    history.push(`${location.pathname}/${setup.id}`)
  }

  return (
    <Navigation history={history}>
      {setups ? (
        <div className={classes.container}>
          <div className={classes.headerContainer}></div>

          <DataGrid
            rows={setups}
            rowHeight={30}
            columns={columns}
            getRowId={row => row.id}
            disableColumnSelector
            onRowClick={editSetup}
            sx={overridesWeirdResizing}
          />
        </div>
      ) : (
        <div style={{ height: '100%', margin: '25% 50%' }}>
          <CircularProgress />
        </div>
      )}
    </Navigation>
  )
}

const mapStateToProps = state => ({
  setups: state.setups,
  userPermissions: state.userPermissions.permissions,
})

export default connect(mapStateToProps, { getSetups })(Setups)
