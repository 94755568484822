import axios from 'axios'
import { transformSelectedSetup } from '../api/utils'

const API_URL = `${process.env.REACT_APP_API_URL}`

const Overview = {
  getOverview(setup) {
    return axios({
      method: 'get',
      url: `${API_URL}/overview/${transformSelectedSetup(setup)}`,
    })
  },
}

export default Overview
