import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Navigation from '../settings-navigation/Navigation'
import { CustomButton } from '../../components/restyled-mui/CustomButton'
import { DataGrid } from '@mui/x-data-grid'
import { getUsers } from '../../redux/actions/users'
import { connect } from 'react-redux'
import { getSettingsStyles } from '../../styles/settingsStyles'

const columns = [
  {
    field: 'name',
    headerName: 'USERNAME',
    flex: 2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'email',
    headerName: 'EMAIL',
    flex: 2,
    headerClassName: 'super-app-theme--header',
  },
]

const useStyles = makeStyles(theme => ({
  ...getSettingsStyles(theme),
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '50px 0 20px 0',
  },

  container: {
    textAlign: 'center',
    width: '90%',
    margin: '0 auto',
  },
}))

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
}

const Users = props => {
  const classes = useStyles(props)
  const location = useLocation()
  const { history, users, getUsers } = props

  useEffect(() => {
    if (!users) {
      getUsers()
    }
  }, [users, getUsers])

  const handleCreateUser = () => {
    history.push(`${location.pathname}/create`)
  }

  const handleEditUser = params => {
    const user = users.find(user => user.id === params.row.id)
    history.push(`${location.pathname}/${user.id}`)
  }

  return (
    <Navigation history={history}>
      {users ? (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <CustomButton
              onClick={handleCreateUser}
              variant="contained"
              disableElevation
              color="primary"
              className={classes.addNewButton}
            >
              Add new
            </CustomButton>
          </div>

          <DataGrid
            rows={users}
            rowHeight={30}
            columns={columns}
            getRowId={row => row.id}
            disableColumnSelector
            onRowClick={handleEditUser}
            sx={overridesWeirdResizing}
          />
        </div>
      ) : (
        <div style={{ height: '100%', margin: '25% 50%' }}>
          <CircularProgress />
        </div>
      )}
    </Navigation>
  )
}

const mapStateToProps = state => ({
  users: state.users,
})

export default connect(mapStateToProps, { getUsers })(Users)
