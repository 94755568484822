import React, { useState } from 'react'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AnalysisForm from '../components/forms/AnalysisForm'

const sitesListWidth = 345
const tabletSidebarWidth = 50

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '10%' : sitesListWidth),
  },
}))

const AnalysisPanel = props => {
  const classes = useStyles(props)
  const {
    setAnalysisParameters,
    userSetups,
    handleNoDataContentUpdate,
    disableButton,
    setAnalysisData,
    loading,
  } = props
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Drawer
        open={true}
        variant={'persistent'}
        anchor={'left'}
        s
        ModalProps={{
          keepMounted: true,
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: { position: 'absolute' },
          className: classes.drawerPaper,
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        onClose={toggleOpen}
        classes={{ paper: classes.drawer }}
      >
        <AnalysisForm
          setAnalysisParameters={setAnalysisParameters}
          userSetups={userSetups}
          handleNoDataContentUpdate={handleNoDataContentUpdate}
          disableButton={disableButton}
          setAnalysisData={setAnalysisData}
          loading={loading}
        ></AnalysisForm>
      </Drawer>
      <div className={classes.childrenContainer}>{props.children}</div>
    </>
  )
}

export default AnalysisPanel
