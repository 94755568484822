import { SETUP } from '../types'

export const setupObj = {
  name: null,
  alias: null,
  client_id: null,
}

const setup = (state = setupObj, action) => {
  const { type, payload } = action

  switch (type) {
    case SETUP.LOADED:
      return {
        ...state,
        name: payload.name,
        alias: payload.alias,
        client_id: payload.client_id,
      }

    default:
      return state
  }
}

export default setup
