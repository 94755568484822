import { USER_PERMISSIONS } from '../types'

const initPermissions = {
  permissions: [],
}

const userPermissions = (state = initPermissions, action) => {
  switch (action.type) {
    case USER_PERMISSIONS:
      return { ...state, permissions: action.payload }
    default:
      return state
  }
}

export default userPermissions
