import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  loading: {
    //  transform: 'translate(-50%, -50%)',
    // position: 'absolute',
    // top: '50%',
    // left: '50%',

    marginTop: '25%',
    marginLeft: '50%',
  },
}))

const CenteredLoading = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  )
}

export default CenteredLoading
