import { USER } from '../types'

export const userObj = {
  username: null,
  password: null,
  permissions: [],
  setups: [],
  userId: null,
  timezone: '',
  unit: '',
}

export const user = (state = userObj, action) => {
  const { type, payload } = action

  switch (type) {
    case USER.LOADED:
      return {
        ...state,
        username: payload.username,
        password: payload.password,
        permissions: payload.permissions,
        setups: payload.setups,
        userId: payload.userId,
        timezone: payload.timezone,
        unit: payload.unit,
      }

    default:
      return state
  }
}
