import React, { useEffect } from 'react'
import * as am4maps from '@amcharts/amcharts4/maps'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_material from '@amcharts/amcharts4/themes/material'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'

am4core.useTheme(am4themes_material)

const GlobalMap = props => {
  const { data } = props

  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('chartdiv', am4maps.MapChart)
    am4core.addLicense('ch-custom-attribution')
    chart.logo.dispose()

    chart.geodata = am4geodata_worldLow
    chart.projection = new am4maps.projections.Miller()

    // Center the point on the middle of the top and bottom points
    const maxLat = Math.max(...data.map(d => d.latitude))
    const minLat = Math.min(...data.map(d => d.latitude))
    const avgLat = (maxLat + minLat) / 2
    const maxLon = Math.max(...data.map(d => d.longitude))
    const minLon = Math.min(...data.map(d => d.longitude))
    const avgLon = (maxLon + minLon) / 2
    chart.homeGeoPoint = {
      latitude: avgLat,
      longitude: avgLon,
    }

    // To decide on the zoom level, find the distance to the edge in lat and lon
    const latDist = maxLat - minLat
    const lonDist = maxLon - minLon
    const maxDist = Math.max(latDist, lonDist, 3)

    // Zoom out a bit so that the points are not on the edge
    chart.homeZoomLevel = 100 / maxDist
    // chart.homeZoomLevel = 25
    chart.maxZoomLevel = 1000
    chart.minZoomLevel = 1
    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true
    // Exclude Antartica
    polygonSeries.exclude = ['AQ']

    // Configure map polygon appearance
    let polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.fill = am4core.color('#CCCCCC')

    // Create map point series for your data
    let pointSeries = chart.series.push(new am4maps.MapImageSeries())

    // Create a map point template
    let pointTemplate = pointSeries.mapImages.template
    pointTemplate.propertyFields.latitude = 'latitude'
    pointTemplate.propertyFields.longitude = 'longitude'

    // Set up proportional size of the points based on the value

    // Create a circle for each data point
    let circle = pointTemplate.createChild(am4core.Circle)
    circle.stroke = am4core.color('#FFFFFF')
    // Add transparency to the circle
    circle.fillOpacity = 0.7
    circle.strokeOpacity = 0.7
    circle.strokeWidth = 2
    circle.nonScaling = true
    circle.tooltipText =
      'Name: [bold]{name}[/]\n Installed Capacity: [bold]{installedCapacity}[/] KW\n Technology: [bold]{technology}[/]'

    const max = Math.max(...data.map(d => d.installedCapacity))
    const min = Math.min(...data.map(d => d.installedCapacity))
    const maxSize = 30
    const minSize = 6
    circle.adapter.add('radius', function (radius, target) {
      if (max === min) {
        return 10
      }
      return (
        ((target.dataItem.dataContext.installedCapacity - min) / (max - min)) *
          (maxSize - minSize) +
        minSize
      )
    })

    circle.adapter.add('fill', function (fill, target) {
      if (target.dataItem && target.dataItem.dataContext) {
        //I do not know why this is needed here and not on the readius adapter
        if (target.dataItem.dataContext.technology === 'Wind') {
          return am4core.color('#F05039')
        } else {
          return am4core.color('#1F449C')
        }
      } else {
        return am4core.color('#CCCCCC')
      }
    })

    // Add data to the point series
    pointSeries.data = data

    // Finally, make sure to dispose of the chart when the component is unmounted
    return () => {
      chart.dispose()
    }
  }, [data]) // Re-render the chart when data changes

  return (
    <div
      id="chartdiv"
      style={{ width: '100%', height: '100%', border: '1px solid #ccc' }}
    ></div>
  )
}

export default GlobalMap
