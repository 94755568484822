import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MetricsPanel from '../panels/MetricsPanel'
import NoSite from '../components/NoSite'
import { Box, Tab, Typography, Grid, CircularProgress } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import MetricsTimeSeriesChart from '../charts/Metrics/MetricsTimeSeriesChart'
import Parameters from '../components/MetricsParameter'
import MetricsResidulChart from '../charts/Metrics/MetricsResidualChart'
import MetricsOnlineChart from '../charts/Metrics/MetricsOnlineChart'
import Api from '../api'
import axios from 'axios'
import { setSelectedUserSetup } from '../redux/actions/userSetups'

const useStyles = makeStyles(theme => ({
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  subCaption: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: '600',
  },
  paramContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px 10px',
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const MetricsPage = props => {
  const classes = useStyles(props)
  const { container, userSetups, selectedSetup, setSelectedUserSetup } = props

  const [loading, setLoading] = useState(false)
  const [mainTabValue, setMainTabValue] = useState('1')
  const [subTabValue, setSubTabValue] = useState('0')

  const [metricsData, setMetricsData] = useState(null)
  const [selectedPlant, setSelectedPlant] = useState(null)
  const disableButton = metricsData !== null || loading ? true : false

  const [displayChart, setDisplayChart] = useState([true, false, false])
  const [visibleChart, setVisibleChart] = useState(0)
  const [selectedForecast, setSelectedForecast] = useState(null)

  // for disabling inputs in form
  const [trackDisableStatus, setTrackDisableStatus] = useState(false)

  let initNodata = {
    heading: 'No setup selected',
    body: 'Select a setup and adjust parameters in order to start analysis',
  }

  if (selectedSetup) {
    initNodata = {
      heading: '',
      body: '',
    }
  }

  const [nodataContent, setNodataContent] = useState(initNodata)

  const handleMainTabChange = (_, newValue) => {
    setMainTabValue(newValue)
  }

  const handleSubTabChange = (_, newValue) => {
    const newState = [...displayChart]
    newState[newValue] = true
    setDisplayChart(newState)
    setVisibleChart(newValue)
    setSubTabValue(newValue)
  }

  const handlePlantChange = newPlant => {
    setSelectedPlant(newPlant)
  }

  const startMetricsData = async metricsObj => {
    setLoading(true)
    setTrackDisableStatus(true)
    try {
      const response = await Api.Metrics.getMetrics(metricsObj)
      if (response.status === 204) {
        setMetricsData(null)
        setTrackDisableStatus(false)
        setNodataContent({
          heading: `No data for selected setup: ${selectedSetup}`,
          body: '',
        })
      }

      if (response.status === 200) {
        setMetricsData(response.data)
      }

      setLoading(false)
    } catch (error) {
      setMetricsData(null)
      setTrackDisableStatus(false)
      if (error.response.status === 411) {
        setNodataContent({
          heading: `Error running query for selected setup: ${selectedSetup}`,
          body: '',
        })
      } else {
        setNodataContent({
          heading: `Error fetching data for selected setup: ${selectedSetup}`,
          body: '',
        })
      }
      console.error('Error fetching Metrics data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleStartMetric = metricsObj => {
    startMetricsData(metricsObj)
  }

  const handleNoDataContentUpdate = newContent => {
    setNodataContent(newContent)
    setMetricsData(null)
  }

  /*
  This function is separated from the tab context so that you don't have 
  to render the charts again while switching between tabs
  */

  const panels = () => {
    return (
      <>
        {displayChart[0] && (
          <div
            value="0"
            style={{
              padding: '0',
              display: visibleChart == '0' ? 'block' : 'none',
            }}
          >
            <Grid container spacing={0}>
              <Grid item md={12}>
                <MetricsTimeSeriesChart
                  chartData={metricsData['data']}
                  chartName={'chartMetricsTimeSeries'}
                  selectedSetup={selectedSetup}
                  setTrackDisableStatus={setTrackDisableStatus}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {displayChart[1] && (
          <div
            value="1"
            style={{
              padding: '0',
              display: visibleChart == '1' ? 'block' : 'none',
            }}
          >
            {mainTabValue === '1' ? (
              <Grid container spacing={0} key={mainTabValue}>
                <Grid item md={6}>
                  <MetricsResidulChart
                    chartData={metricsData['data']}
                    chartName={'chartMetricsResidualOnline'}
                    chartType={'online'}
                    setTrackDisableStatus={setTrackDisableStatus}
                  ></MetricsResidulChart>
                </Grid>
                <Grid item md={6}>
                  <MetricsOnlineChart
                    chartData={metricsData['data']}
                    chartName={'chartMetricsOnlineOnline'}
                    chartType={'online'}
                    setTrackDisableStatus={setTrackDisableStatus}
                  ></MetricsOnlineChart>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0} key={mainTabValue}>
                <Grid item md={6}>
                  <MetricsResidulChart
                    chartData={metricsData['data']}
                    chartName={'chartMetricsResidualMetering'}
                    chartType={'metering'}
                    setTrackDisableStatus={setTrackDisableStatus}
                  ></MetricsResidulChart>
                </Grid>
                <Grid item md={6}>
                  <MetricsOnlineChart
                    chartData={metricsData['data']}
                    chartName={'chartMetricsOnlineMetering'}
                    chartType={'metering'}
                    setTrackDisableStatus={setTrackDisableStatus}
                  ></MetricsOnlineChart>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {displayChart[2] && (
          <div
            value="2"
            style={{ display: visibleChart == '2' ? 'block' : 'none' }}
          >
            <Grid container spacing={0}>
              <Grid item md={12}>
                <MetricsTimeSeriesChart
                  chartData={metricsData}
                  chartName={'chartMetricsTimeSeries33'}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </>
    )
  }

  const resultsCharts = () => {
    return (
      <>
        <Typography className={classes.subCaption}>
          FORECAST VS. OBSERVATIONS
        </Typography>
        <TabContext value={subTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleSubTabChange}
              aria-label="sub tabs for Online"
            >
              <Tab label="Time Series Plot" value="0" />
              <Tab label="Residual Plot" value="1" />
              {selectedForecast === 'aggregated' && (
                <Tab label="Coverage Plot" value="2" />
              )}
            </TabList>
          </Box>
        </TabContext>
        {panels()}
      </>
    )
  }

  const resultPanel = () => {
    return metricsData !== null ? (
      <>
        <TabContext value={mainTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleMainTabChange} aria-label="Metrics charts">
              <Tab label="Online" value="1" />
              <Tab label="Metering" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: 0 }}>
            <div className={classes.paramContainer}>
              <Parameters label={'nmae'} value={metricsData.OnlineNmae} />
              <Parameters label={'nrmse'} value={metricsData.OnlineNrmse} />
              <Parameters label={'mae'} value={metricsData.OnlineMae} />
              <Parameters label={'rmse'} value={metricsData.OnlineRmse} />
            </div>
          </TabPanel>

          <TabPanel value="2" sx={{ padding: 0 }}>
            <div className={classes.paramContainer}>
              <Parameters label={'nmae'} value={metricsData.MeteringNmae} />
              <Parameters label={'nrmse'} value={metricsData.MeteringNrmse} />
              <Parameters label={'mae'} value={metricsData.MeteringMae} />
              <Parameters label={'rmse'} value={metricsData.MeteringRmse} />
            </div>
          </TabPanel>
        </TabContext>
        {resultsCharts()}
      </>
    ) : (
      <NoSite
        {...props}
        creating={false}
        heading={nodataContent.heading}
        body={nodataContent.body}
      ></NoSite>
    )
  }

  // If there is only 1 available setup, select it automatically
  if (userSetups.length === 1) {
    setSelectedUserSetup(userSetups[0])
  }

  return (
    <>
      <MetricsPanel
        container={container}
        userSetups={userSetups}
        selectedPlant={selectedPlant}
        setSelectedPlant={handlePlantChange}
        setMetricsParameters={handleStartMetric}
        handleNoDataContentUpdate={handleNoDataContentUpdate}
        setMetricsData={setMetricsData}
        onChangeSelectedForecast={selVal => setSelectedForecast(selVal.name)}
        disableButton={disableButton}
        trackDisableStatus={trackDisableStatus}
      >
        {!loading ? (
          resultPanel()
        ) : (
          <div className={classes.centerItem}>
            <CircularProgress />
          </div>
        )}
      </MetricsPanel>
    </>
  )
}

const mapStateToProps = state => ({
  selectedSetup: state.userSetups.selectedSetup,
  userSetups: state.userSetups.userSetups,
})

const mapDispatchToProps = {
  setSelectedUserSetup,
}
export default connect(mapStateToProps, mapDispatchToProps)(MetricsPage)
