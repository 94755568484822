import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

const Analysis = {
  createAnalysis(data) {
    return axios({
      method: 'post',
      url: `${API_URL}/analysis`,
      data,
    })
  },
}

export default Analysis
