import React from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  FormControl,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { CustomInputLabel, CustomTextField } from '../restyled-mui/CustomInputs'

import HelpIcon from '@material-ui/icons/Help'
import { Autocomplete } from '@mui/material'
import { setSelectedUserSetup } from '../../redux/actions/userSetups'

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
  },
  customSubtitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
}))

const PortfolioHistoryForm = props => {
  const classes = useStyles(props)
  const {
    selectedSetup,
    setSelectedUserSetup,
    userSetups,
    trackDisableStatus,
  } = props

  const isEmptyOrContainsUndefined =
    userSetups.length === 0 || userSetups.some(x => x === undefined)

  return (
    <div className={classes.formContainer}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <Typography
            variant="h4"
            className={clsx(classes.formSubtitle, classes.customSubtitle)}
          >
            Setup selection
          </Typography>
          {!isEmptyOrContainsUndefined ? (
            <Grid container spacing={4}>
              <Grid item md={12}>
                <div className={classes.tooltipContainer}>
                  <CustomInputLabel>Setup Name</CustomInputLabel>
                  <Tooltip
                    title="You can search for a value by typing in the box!"
                    placement="top"
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <span>
                      <IconButton
                        style={{ padding: '0' }}
                        aria-label="info"
                        disableTouchRipple
                      >
                        <HelpIcon
                          fontSize="small"
                          className={classes.helpIcon}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
                <FormControl fullWidth variant="outlined" size="small">
                  <Autocomplete
                    disabled={trackDisableStatus}
                    variant="outlined"
                    size="small"
                    options={userSetups}
                    value={selectedSetup}
                    autoHighlight
                    onChange={(_, newValue) => {
                      setSelectedUserSetup(newValue)
                    }}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { padding: '1px 5px' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <CustomInputLabel>
              No available setup for selection or error occurred.
            </CustomInputLabel>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selectedSetup: state.userSetups.selectedSetup,
  }
}

const mapDispatchToProps = {
  setSelectedUserSetup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortfolioHistoryForm)
