import React, { useRef, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Navigation from './features/navigation/Navigation'
import PortfolioOverviewPage from './pages/PortfolioOverview'
import MetricsPage from './pages/Metrics'
import AnalysisPage from './pages/Analysis'
import PortfolioHistoryPage from './pages/PortfolioHistory'
import SettingsPage from './pages/Settings'
import WikiPage from './pages/Wiki'

import { moduleIsAvailable, modules } from './utils/moduleConfig'
import PageNotFound from './pages/PageNotFound'
import { useHistory } from 'react-router-dom'
import CenteredLoading from './components/CenteredLoading'

const App = props => {
  const navContainer = useRef()
  const history = useHistory()

  const { logout, userPermissions } = props
  const [lowestRouteModuleURL, setLowestRouteModuleURL] = useState('')

  useEffect(() => {
    const fetchLowestRouteModuleURL = async () => {
      if (userPermissions.length !== 0) {
        const lowestRoute = userPermissions.reduce((lowest, moduleName) => {
          const module = modules[moduleName]
          return module && module.route < lowest ? module.route : lowest
        }, Infinity)

        const lowestRouteModuleURL = Object.values(modules).find(
          module => module.route === lowestRoute,
        ).url

        setLowestRouteModuleURL(lowestRouteModuleURL)
      }
    }

    fetchLowestRouteModuleURL()
  }, [userPermissions])

  const goToHomepage = () => {
    history.push('/')
  }

  return (
    <>
      {userPermissions.length !== 0 ? (
        <>
          <Navigation
            navContainerRef={navContainer}
            logout={logout}
            availableModules={userPermissions}
          >
            <Switch>
              <Redirect exact from="/" to={lowestRouteModuleURL} />
              {moduleIsAvailable(modules.overview.id, userPermissions) && (
                <Route
                  path={modules.overview.url}
                  exact
                  render={props => (
                    <PortfolioOverviewPage
                      {...props}
                      container={navContainer}
                    />
                  )}
                />
              )}
              {moduleIsAvailable(modules.history.id, userPermissions) && (
                <Route
                  path={modules.history.url}
                  exact
                  render={props => (
                    <PortfolioHistoryPage {...props} container={navContainer} />
                  )}
                />
              )}
              {moduleIsAvailable(modules.metrics.id, userPermissions) && (
                <Route
                  path={modules.metrics.url}
                  exact
                  render={props => (
                    <MetricsPage {...props} container={navContainer} />
                  )}
                />
              )}
              {moduleIsAvailable(modules.analysis.id, userPermissions) && (
                <Route
                  path={modules.analysis.url}
                  exact
                  render={props => (
                    <AnalysisPage {...props} container={navContainer} />
                  )}
                />
              )}
              {moduleIsAvailable(modules.settings.id, userPermissions) && (
                <Route
                  path={modules.settings.url}
                  render={props => <SettingsPage {...props} />}
                />
              )}

              <Route
                path={modules.wiki.url}
                render={props => <WikiPage {...props} />}
              />

              <Route
                render={props => <PageNotFound goToHomepage={goToHomepage} />}
              />
            </Switch>
          </Navigation>
        </>
      ) : (
        <CenteredLoading />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  userPermissions: state.userPermissions.permissions,
})

export default connect(mapStateToProps)(App)
