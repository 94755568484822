import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import { STATUS } from '../../redux/types'
import { changeStatus } from '../../redux/actions/status'

function mapDispatchToProps(dispatch) {
  return {
    changeStatus: path => dispatch(changeStatus(path)),
  }
}

const mapStateToProps = state => {
  return {
    snackbar: state.status.snackbar,
  }
}

const StatusSnackbar = props => {
  const { changeStatus } = props
  const { open, message, severity } = props.snackbar
  // Severity can be: "error", "warning", "info", "success" or null

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => changeStatus(STATUS.RESET)}
    >
      <Alert
        onClose={() => changeStatus(STATUS.RESET)}
        variant="filled"
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusSnackbar)
