import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  label: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  value: {
    fontSize: 28,
    fontWeight: '400',
    // backgroundColor: 'lightgray',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
  },
}))

const Parameters = ({ label, value }) => {
  const classes = useStyles()

  const formatParam = (label, value) => {
    let suffix = ''
    switch (label) {
      case 'nmae':
      case 'nrmse':
        suffix = value ? '%' : '-%'
        break
      case 'mae':
      case 'rmse':
        suffix = value ? 'mw' : '-mw'
        break
      default:
        break
    }
    return value ? `${value}${suffix}` : suffix
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>
        {formatParam(label, value)}
      </Typography>
    </div>
  )
}

export default Parameters
