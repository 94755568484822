import React, { useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import LogoutMenu from './Logout'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userIcon } from '../../assets/fontawesome/userIcon'
import { menuBarsIcon } from '../../assets/fontawesome/menuBarsIcon'
import { conwxLogoIcon } from '../../assets/fontawesome/conwxLogoIcon'
import { menuPushBreakpointUp } from './Navigation'

const useStyles = makeStyles(theme => ({
  appBar: {
    transform: 'translateZ(0)',
    backgroundColor: theme.palette.menu.bg,
    borderBottom: `1px solid ${theme.palette.menu.border}`,
    borderLeft: `1px solid ${theme.palette.menu.border}`,
    width: '100%',
    height: props => props.topbarHeight,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    padding: theme.spacing(0, 2, 0, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: props => props.topbarHeight,
    minHeight: props => props.topbarHeight,
  },
  headerText: {
    fontSize: 20,
    paddingBottom: 0,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  leftContainer: {
    height: '100%',
    boxSizing: 'border-box',
    flex: 1,
    [theme.breakpoints.up(menuPushBreakpointUp)]: {},
  },
  rightContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  profileIconButton: {
    padding: theme.spacing(2.5),
  },
  profileIconLabel: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  userIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  logo: {
    height: '50%',
    boxSizing: 'border-box',
    padding: '12px',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const Topbar = props => {
  const { text, menuOverlay, handleDrawerChange, logout } = props

  const classes = useStyles(props)
  const [logoutMenuAnchor, setLogoutMenuAnchor] = useState(null)

  const handleProfileClick = event => {
    setLogoutMenuAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setLogoutMenuAnchor(null)
  }

  return (
    <>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftContainer}>
            {menuOverlay && (
              <IconButton onClick={handleDrawerChange}>
                <FontAwesomeIcon
                  icon={menuBarsIcon}
                  size={'sm'}
                  className={classes.menuIcon}
                />
              </IconButton>
            )}
            <FontAwesomeIcon className={classes.logo} icon={conwxLogoIcon} />
          </div>
          <Typography variant="h3" className={classes.headerText}>
            {text}
          </Typography>
          <div className={classes.rightContainer}>
            <IconButton
              classes={{
                root: classes.profileIconButton,
                label: classes.profileIconLabel,
              }}
              onClick={handleProfileClick}
            >
              <FontAwesomeIcon
                className={classes.userIcon}
                icon={userIcon}
                size={'sm'}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <LogoutMenu
        anchorEl={logoutMenuAnchor}
        handleClose={handleClose}
        logout={logout}
      />
    </>
  )
}

export default Topbar
