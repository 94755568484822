import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

const Setups = {
  getSetups() {
    return axios({
      method: 'get',
      url: `${API_URL}/get_setups`,
    })
  },

  createSetup(setup) {
    return axios({
      method: 'post',
      url: `${API_URL}/add_setup`,
      data: setup,
    })
  },

  deleteSetup(id) {
    return axios({
      method: 'delete',
      url: `${API_URL}/delete_setup/${id}`,
    })
  },

  updateSetup(updatedSetup) {
    return axios({
      method: 'put',
      url: `${API_URL}/edit_setup`,
      data: updatedSetup,
    })
  },
}

export default Setups
