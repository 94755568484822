import React, { useEffect, useState, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Box, Typography } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'

const useStyles = makeStyles(theme => ({
  customSubtitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '30px',
  },
}))

const createHistogramData = source => {
  const data = []
  const min = Math.min(...source)
  const max = Math.max(...source)

  const ranges = [
    { range: 10, step: 0.5 },
    { range: 20, step: 0.5 },
    { range: 50, step: 1 },
    { range: 100, step: 1 },
    { range: 500, step: 5 },
    { range: 1000, step: 10 },
    { range: 5000, step: 50 },
    { range: 10000, step: 100 },
    { range: 20000, step: 200 },
    { range: 50000, step: 500 },
    { range: 100000, step: 1000 },
    { range: 200000, step: 2000 },
    { range: 500000, step: 5000 },
    { range: Infinity, step: 10000 },
  ]

  const { step } = ranges.find(({ range }) => max - min < range)
  const roundedMin = Math.floor(min / step) * step
  const roundedMax = Math.ceil(max / step) * step

  const numCols = Math.ceil((roundedMax - roundedMin) / step)

  for (let i = 0; i < numCols; i++) {
    const from = roundedMin + i * step
    const to = from + step

    data.push({
      from,
      to,
      count: 0,
    })
  }

  source.forEach(value => {
    const index = Math.floor((value - roundedMin) / step)
    if (index >= 0 && index < data.length) {
      data[index].count++
    }
  })

  return data
}

const MetricsResidualChart = props => {
  const classes = useStyles(props)
  const { chartData, chartName, chartType, setTrackDisableStatus } = props
  const [chartIsReady, setChartIsReady] = useState(false)
  const containerRef = useRef(null)

  const unit =
    loadStateFromLocalStorage('unit') !== undefined
      ? loadStateFromLocalStorage('unit')
      : 'kW'

  useEffect(() => {
    const initializeChart = () => {
      try {
        if (chartData.length === 0) {
          console.error('Data for Metrics-Residual chart is empty.')
        } else if (typeof am5.registry.rootElements === 'undefined') {
          console.error('RootElements in Metrics-Residual chart is undefined.')
        } else {
          am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id === chartName) {
              root.dispose()
            }
          })

          const selectedType = chartType === 'online' ? 'Online' : 'Metering'

          const residualData = chartData.map(item =>
            unit === 'MW' ? item[selectedType] / 1000 : item[selectedType],
          )
          residualData.sort((a, b) => a - b)
          const residualsOnline = createHistogramData(residualData)

          const root = am5.Root.new(chartName)
          root._logo.dispose()
          // root.setThemes([am5themes_Animated.new(root)])
          root.numberFormatter.set('numberFormat', '#')

          // Create chart
          const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: 'panX',
              wheelY: 'zoomX',
              layout: root.verticalLayout,
            }),
          )

          // Create count Y-axis
          const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              maxPrecision: 2,
              marginRight: 20,
              renderer: am5xy.AxisRendererY.new(root, {}),
            }),
          )

          const countLabel = am5.Label.new(root, {
            rotation: -90,
            text: 'Count',
            y: am5.p50,
            centerX: am5.p50,
          })
          yAxis.children.unshift(countLabel)

          // Create Y-axis - add extra space right
          const yExtraSpaceRight = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              maxPrecision: 0,
              syncWithAxis: yAxis,
              renderer: am5xy.AxisRendererY.new(root, {
                opposite: true,
              }),
            }),
          )
          yExtraSpaceRight.children.moveValue(
            am5.Label.new(root, {
              text: '',
              y: am5.p50,
              centerX: am5.p50,
            }),
            0,
          )

          // Create residual online X-axis
          const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: 'from',
              marginTop: 20,
              renderer: am5xy.AxisRendererX.new(root, {}),
              tooltip: am5.Tooltip.new(root, {}),
            }),
          )

          const residualOnlineLabel = am5.Label.new(root, {
            text:
              chartType === 'online'
                ? `Residuals Online (${unit})`
                : `Residuals Metering (${unit})`,
            x: am5.percent(35),
          })
          xAxis.children.push(residualOnlineLabel)

          // Remove vertical grid lines
          xAxis.get('renderer').grid.template.setAll({
            strokeOpacity: 0,
          })

          xAxis.data.setAll(residualsOnline)

          // Add residual plot series
          const residualPlotSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name:
                chartType === 'online'
                  ? 'Residuals_Online'
                  : 'Residuals_Metering',
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: 'count',
              categoryXField: 'from',
              valueXField: 'to',
            }),
          )

          residualPlotSeries.columns.template.setAll({
            tooltipText:
              '{name}:[bold]{categoryX} - [bold]{valueX}[/]\nProbability: [bold]{valueY}',
            // width: am5.percent(90),
            tooltipY: 0,
            width: am5.p100,
          })

          residualPlotSeries.data.setAll(residualsOnline)

          am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {
              // align: 'right',
              // valign: 'bottom',
            }),
            dataSource: residualData,
            filePrefix: `Conwx_Residual_Histogram_${selectedType}`,
          })

          // catch the event when chart is ready
          let timeout
          root.events.on('frameended', exportChart)
          function exportChart() {
            if (timeout) {
              clearTimeout(timeout)
            }
            timeout = setTimeout(function () {
              root.events.off('frameended', exportChart)
              setChartIsReady(true)
              setTrackDisableStatus(false)
            }, 100)
          }
        }
      } catch (error) {
        console.error(`Error in Metrics Residuals ${chartType} chart:`, error)
      }
    }

    if (chartData && containerRef.current && !chartIsReady) {
      setTimeout(() => {
        initializeChart()
      }, 100)
    }
  }, [chartData, chartIsReady, chartName])

  return (
    <>
      {!chartIsReady && (
        <Box sx={{ width: '40%', margin: '50% 35%' }}>
          <Typography
            variant="h4"
            className={clsx(classes.formSubtitle, classes.customSubtitle)}
          >
            Generating graph
          </Typography>
          <LinearProgress />
        </Box>
      )}
      <div
        ref={containerRef}
        id={chartName}
        style={{
          width: '100%',
          height: '700px',
          marginTop: '50px',
          opacity: chartIsReady ? '1' : '0',
        }}
      ></div>
    </>
  )
}

export default MetricsResidualChart
