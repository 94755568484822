import { USER_PERMISSIONS } from '../types'
import { saveStateToLocalStorage } from '../utilsLocalStorage'

export const userPermissions = permissions => {
  return dispatch => {
    dispatch({
      type: USER_PERMISSIONS,
      payload: permissions,
    })
    saveStateToLocalStorage(permissions, 'permissions')
  }
}
