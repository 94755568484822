import React, { useEffect, useState, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Box, Typography } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'

const useStyles = makeStyles(theme => ({
  customSubtitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '30px',
  },
}))

const MetricsOnlineChart = props => {
  const classes = useStyles(props)
  const { chartData, chartName, chartType, setTrackDisableStatus } = props
  const [chartIsReady, setChartIsReady] = useState(false)
  const containerRef = useRef(null)

  const unit =
    loadStateFromLocalStorage('unit') !== undefined
      ? loadStateFromLocalStorage('unit')
      : 'kW'

  useEffect(() => {
    const initializeChart = () => {
      try {
        if (chartData.length === 0) {
          console.error('Data for Metrics-Online chart is empty.')
        } else if (typeof am5.registry.rootElements === 'undefined') {
          console.error('RootElements in Metrics-Online chart is undefined.')
        } else {
          am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id === chartName) {
              root.dispose()
            }
          })

          const selectedType = chartType === 'online' ? 'Online' : 'Metering'

          const finalData = chartData.map(item => ({
            Forecast_50:
              unit === 'MW' ? item['Forecast_50'] / 1000 : item['Forecast_50'],
            Online: unit === 'MW' ? item['Online'] / 1000 : item['Online'],
            Metering:
              unit === 'MW' ? item['Metering'] / 1000 : item['Metering'],
          }))

          const root = am5.Root.new(chartName)
          // root.setThemes([am5themes_Animated.new(root)])
          root._logo.dispose()
          root.numberFormatter.set('numberFormat', '#')

          // Create chart
          const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: true,
              panY: true,
              wheelY: 'zoomXY',
              pinchZoomX: true,
              pinchZoomY: true,
            }),
          )

          // Create forecast Y-axis
          const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              extraMax: 0.1,
              marginRight: 20,
              renderer: am5xy.AxisRendererY.new(root, {}),
              tooltip: am5.Tooltip.new(root, {}),
            }),
          )

          const forecastLabel = am5.Label.new(root, {
            text: `Forecast_50 (${unit})`,
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50,
          })
          yAxis.children.unshift(forecastLabel)

          // Create Y-axis - add extra space right
          const yExtraSpaceRight = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              maxPrecision: 0,
              syncWithAxis: yAxis,
              renderer: am5xy.AxisRendererY.new(root, {
                opposite: true,
              }),
            }),
          )

          yExtraSpaceRight.children.moveValue(
            am5.Label.new(root, {
              text: '',
              y: am5.p50,
              centerX: am5.p50,
            }),
            0,
          )

          // Create online X-axes
          const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              extraMax: 0.1,
              marginTop: 20,
              renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
              tooltip: am5.Tooltip.new(root, {}),
            }),
          )

          xAxis.children.push(
            am5.Label.new(root, {
              text: `${selectedType} (${unit})`,
              x: am5.p50,
              centerX: am5.p50,
            }),
          )

          xAxis.get('renderer').grid.template.setAll({
            stroke: am5.color(0xff0000),
            strokeWidth: 3,
            strokeOpacity: 0,
          })

          // Create series
          const circleSeries = chart.series.push(
            am5xy.LineSeries.new(root, {
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: 'Forecast_50',
              valueXField: `${selectedType}`,
            }),
          )

          // Remove connection lines
          circleSeries.strokes.template.set('visible', false)

          // Adding circle element
          circleSeries.bullets.push(function (root) {
            const formatNumberUnit = unit === 'MW' ? '#.##' : '#.'
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 4,
                fill: circleSeries.get('fill'),
                tooltipText: `Forecast_50: [bold]{valueY.formatNumber('${formatNumberUnit}')} \n[/]${selectedType}: [bold]{valueX.formatNumber('${formatNumberUnit}')}`,
              }),
            })
          })

          circleSeries.data.setAll(finalData)

          // Add cursor
          chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
              xAxis: xAxis,
              yAxis: yAxis,
              snapToSeries: [circleSeries],
            }),
          )

          am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {
              // align: 'right',
              // valign: 'bottom',
            }),
            dataSource: finalData,
            filePrefix: `Conwx_Residuals_Scatter_${chartType}`,
          })

          // catch the event when chart is ready
          let timeout
          root.events.on('frameended', exportChart)
          function exportChart() {
            if (timeout) {
              clearTimeout(timeout)
            }
            timeout = setTimeout(function () {
              root.events.off('frameended', exportChart)
              setChartIsReady(true)
            }, 100)
          }
        }
      } catch (error) {
        console.error(`Error in Metrics ${chartType} chart:`, error)
      }
    }

    if (chartData && containerRef.current && !chartIsReady) {
      setTimeout(() => {
        initializeChart()
        setTrackDisableStatus(false)
      }, 100)
    }
  }, [chartData, chartIsReady, chartName])

  return (
    <>
      {!chartIsReady && (
        <Box sx={{ width: '40%', margin: '50% 35%' }}>
          <Typography
            variant="h4"
            className={clsx(classes.formSubtitle, classes.customSubtitle)}
          >
            Generating graph
          </Typography>
          <LinearProgress />
        </Box>
      )}

      <div
        ref={containerRef}
        id={chartName}
        style={{
          width: '100%',
          height: '700px',
          marginTop: '50px',
          opacity: chartIsReady ? '1' : '0',
        }}
      ></div>
    </>
  )
}

export default MetricsOnlineChart
