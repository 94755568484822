import { METRICS } from '../types'

const currentDate = new Date()
const sevenDaysAgo = new Date()
sevenDaysAgo.setDate(currentDate.getDate() - 7)
const twoDaysForward = new Date()
twoDaysForward.setDate(currentDate.getDate() + 2)

const initialPlant = {
  selectedForecast: { name: 'per_plant', label: 'Per Plant' },
  selectedPlant: null,
  selectedForecastType: 'ID',
  issueTime: 600,
  selectedPercentile: [50],
  selectedDateRange: {
    startDate: sevenDaysAgo,
    endDate: twoDaysForward,
  },
  selectedAggregationLevel: null,
  selectedAggregationValue: '',
  leadTime: 0,
}

const metrics = (state = initialPlant, action) => {
  switch (action.type) {
    case METRICS.FORECAST:
      return { ...state, selectedForecast: action.payload }
    case METRICS.PLANT:
      return { ...state, selectedPlant: action.payload }
    case METRICS.FORECAST_TYPE:
      return { ...state, selectedForecastType: action.payload }
    case METRICS.ISSUE_TIME:
      return { ...state, issueTime: action.payload }
    case METRICS.PERCENTILES:
      return { ...state, selectedPercentile: action.payload }
    case METRICS.DATE:
      return { ...state, selectedDateRange: action.payload }
    case METRICS.AGGREGATION_LEVEL:
      return { ...state, selectedAggregationLevel: action.payload }
    case METRICS.AGGREGATION_VALUE:
      return { ...state, selectedAggregationValue: action.payload }
    case METRICS.LEADTIME:
      return { ...state, leadTime: action.payload }
    default:
      return state
  }
}

export default metrics
