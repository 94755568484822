import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { Toolbar, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import { modules } from '../../utils/moduleConfig'

export const menuOverlayBreakpointDown = 'md'
export const menuPushBreakpointUp = 'lg'

export const topbarHeight = 48

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
    minHeight: `calc(100% - ${topbarHeight}px)`,
    zIndex: 0,
    overflow: 'auto',
  },
  toolbar: {
    height: topbarHeight,
    minHeight: topbarHeight,
  },
}))

const Navigation = props => {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const location = useLocation()

  const menuOverlay = useMediaQuery(
    theme.breakpoints.down(menuOverlayBreakpointDown),
  )

  const { navContainerRef, logout, availableModules } = props

  const handleDrawerChange = () => {
    setOpen(!open)
  }

  const menuLink = path => {
    dispatch(push(path))
    if (menuOverlay) {
      handleDrawerChange()
    }
  }

  const selectedMenuItem = () => {
    switch (true) {
      case location.pathname.includes(modules.overview.url):
        return modules.overview.route
      case location.pathname.includes(modules.history.url):
        return modules.history.route
      case location.pathname.includes(modules.metrics.url):
        return modules.metrics.route
      case location.pathname.includes(modules.analysis.url):
        return modules.analysis.route
      case location.pathname.includes(modules.settings.url):
        return modules.settings.route
      case location.pathname.includes(modules.wiki.url):
        return modules.wiki.route
      default:
        return 0
    }
  }

  const renderTitleText = () => {
    switch (selectedMenuItem()) {
      case modules.overview.route:
        return modules.overview.title
      case modules.history.route:
        return modules.history.title
      case modules.metrics.route:
        return modules.metrics.title
      case modules.analysis.route:
        return modules.analysis.title
      case modules.settings.route:
        return modules.settings.title
      case modules.wiki.route:
        return modules.wiki.title
      default:
        return ''
    }
  }

  return (
    <div className={classes.root}>
      <Topbar
        topbarHeight={topbarHeight}
        logoHidden={true}
        text={renderTitleText()}
        menuOverlay={menuOverlay}
        handleDrawerChange={handleDrawerChange}
        logout={logout}
      />
      <Sidebar
        open={open}
        goTo={menuLink}
        topbarHeight={topbarHeight}
        minDrawerWidth={50}
        menuOverlay={false}
        maxDrawerWidth={220}
        handleDrawerChange={handleDrawerChange}
        selectedMenuItem={selectedMenuItem}
        availableModules={availableModules}
      />
      <main className={classes.main}>
        <Toolbar className={classes.toolbar} />
        <div ref={navContainerRef} className={classes.content}>
          {props.children}
        </div>
      </main>
    </div>
  )
}

export default Navigation
