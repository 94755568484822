import React, { useState } from 'react'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MetricsForm from '../components/forms/MetricsForm'

const sitesListWidth = 345
const tabletSidebarWidth = 50

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: tabletSidebarWidth,
    zIndex: 1400,
    backgroundColor: theme.palette.menu.bg,
    borderRight: `${theme.palette.menu.border} 1px solid`,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
  },
  menuItemIcon: {
    textAlign: 'center',
    minWidth: '100%',
  },
  menuIcon: {
    color: theme.palette.menu.icons,
  },
  selectedMenuIcon: {
    color: theme.palette.primary.main,
  },
  tickIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}))

const MetricsPanel = props => {
  const classes = useStyles(props)
  const {
    container,
    userSetups,
    selectedPlant,
    setSelectedPlant,
    setMetricsParameters,
    handleNoDataContentUpdate,
    setMetricsData,
    onChangeSelectedForecast,
    disableButton,
    trackDisableStatus,
  } = props
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Drawer
        open={true}
        variant={'persistent'}
        anchor={'left'}
        ModalProps={{
          keepMounted: true,
          container: container.current,
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: { position: 'absolute' },
          className: classes.drawerPaper,
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        onClose={toggleOpen}
        classes={{ paper: classes.drawer }}
      >
        <MetricsForm
          userSetups={userSetups}
          selectedPlant={selectedPlant}
          setSelectedPlant={setSelectedPlant}
          setMetricsParameters={setMetricsParameters}
          handleNoDataContentUpdate={handleNoDataContentUpdate}
          setMetricsData={setMetricsData}
          onChangeSelectedForecast={onChangeSelectedForecast}
          disableButton={disableButton}
          trackDisableStatus={trackDisableStatus}
        />
      </Drawer>
      <div className={classes.childrenContainer}>{props.children}</div>
    </>
  )
}

export default MetricsPanel
