import { loadStateFromLocalStorage } from '../../src/redux/utilsLocalStorage'

export const transformSelectedSetup = selSetup => {
  const userPerm = loadStateFromLocalStorage('permissions')
  const key = userPerm.includes('settings') ? 'name' : 'alias'
  return key === 'name'
    ? selSetup
    : loadStateFromLocalStorage('setups').find(
        setup => setup.alias === selSetup,
      ).name
}
