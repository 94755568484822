import React from 'react'
import { Button, Typography, Popover, makeStyles } from '@material-ui/core'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5.5, 12, 7.5, 8),
    backgroundColor: theme.palette.menu.listItemBg,
    borderRadius: theme.spacing(2.5),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.menu.border}`,
  },
  innerContent: {
    padding: theme.spacing(3, 0, 5.5, 0),
  },
  title: {
    fontSize: 14,
    color: theme.palette.menu.text,
    height: 'auto',
  },
  bodyTextName: {
    color: theme.palette.menu.text,
    fontSize: 16,
    height: 'auto',
  },
  logoutButton: {
    width: 116,
  },
}))

const LogoutMenu = props => {
  const classes = useStyles()
  const { anchorEl, handleClose, logout } = props

  const username = loadStateFromLocalStorage('username')

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography variant="h4" className={classes.title}>
        Profile
      </Typography>
      <div className={classes.innerContent}>
        <Typography variant="body1" className={classes.bodyTextName}>
          {username}
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.logoutButton}
        onClick={logout}
      >
        Logout
      </Button>
    </Popover>
  )
}

export default LogoutMenu
