import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from './CheckboxIcon'
import {
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
} from './RadioButtonIcon'
import CloseIcon from './CloseIcon'
import UserIcon from './UserIcon'

//vej og park
import AppIcon from '../ui-icons/AppIcon'

const UIIcons = {
  //ui icons
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  UserIcon,
  //vej og park
  AppIcon,
  CloseIcon,
}

export default UIIcons
