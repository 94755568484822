import {
  Grid,
  Fade,
  InputLabel,
  TextField,
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { basicStyles } from '../styles/styles'

import loginImage1 from '../assets/customers/login_image_1.jpg'
import loginImage2 from '../assets/customers/login_image_2.jpg'
import loginImage3 from '../assets/customers/login_image_3.jpg'
import conwxLogo from '../assets/customers/conwx_logo.svg'

const useStyles = makeStyles(theme => ({
  ...basicStyles(theme),
  // Image container to be able to use a `coverImage`.
  coverImageContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  // Cover Image: used for the background image when we want to show a full page image
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  // container: used for the container of the form and logo in the right drawer, centered
  formContainer: {
    width: 260,
    margin: '65px 80px 65px 80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  //form: used for the form in the right drawer
  form: {
    width: '100%',
  },
  // Logo: used for the logo in the right drawer
  logo: {
    margin: '0px 0px 36px 0px',
    height: 97,
    width: 260,
    '& path': {
      fill: theme.palette.company.logoColor,
    },
  },
}))

const LoginPage = props => {
  const classes = useStyles(props)
  const { onSubmitUser, errorMessage, setErrorMessage, loading } = props

  const possibleLoginImages = [loginImage1, loginImage2, loginImage3]
  const [randomIndex] = useState(
    Math.floor(Math.random() * possibleLoginImages.length),
  )

  const loginImage = possibleLoginImages[randomIndex]

  const [formState, setFormState] = useState({
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
  })

  const validateForm = () => {
    const { username, password } = formState
    const errors = {
      usernameError: !username ? 'Username is required' : '',
      passwordError: !password ? 'Password is required' : '',
    }
    setErrorMessage('')
    setFormState(prevFormState => ({ ...prevFormState, ...errors }))
    return Object.values(errors).every(error => !error)
  }

  const onSubmit = e => {
    e.preventDefault()

    if (validateForm()) {
      const client = {
        name: formState.username,
        password: formState.password,
      }

      onSubmitUser(client, true)
    }
  }

  const onChange = field => e => {
    setFormState({ ...formState, [field]: e.target.value })
  }

  return (
    <Grid container spacing={0} className={classes.fullPageContainer}>
      <Grid item xs className={classes.coverImageContainer}>
        <Fade in={true}>
          <img src={loginImage} className={classes.coverImage} alt="login" />
        </Fade>
      </Grid>
      <Grid item className={classes.drawer}>
        <div className={classes.formContainer}>
          <img src={conwxLogo} alt="conwx logo" className={classes.logo} />

          <form onSubmit={onSubmit} className={classes.form}>
            <div className={classes.inputContainer}>
              <InputLabel>Username</InputLabel>
              <TextField
                className={classes.inputTextField}
                value={formState.username}
                variant="outlined"
                margin="dense"
                size="small"
                disabled={loading}
                error={Boolean(formState.usernameError)}
                helperText={formState.usernameError}
                placeholder="Enter username"
                onChange={onChange('username')}
              />
            </div>
            <div className={classes.inputContainer}>
              <InputLabel>Password</InputLabel>
              <TextField
                className={classes.inputTextField}
                value={formState.password}
                variant="outlined"
                margin="dense"
                size="small"
                disabled={loading}
                type="password"
                error={Boolean(formState.passwordError)}
                helperText={formState.passwordError}
                placeholder="Enter password"
                onChange={onChange('password')}
              />
              <Typography variant="body1" className={classes.inputErrorText}>
                {errorMessage}
              </Typography>
            </div>
            {loading ? (
              <LinearProgress />
            ) : (
              <div className={classes.loadingPlaceholder} />
            )}
            <Button
              variant={'contained'}
              color={'primary'}
              disableElevation
              className={classes.submitButton}
              type="submit"
              style={{ margin: '3px 0px' }}
            >
              Login
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default LoginPage
