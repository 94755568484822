export const basicStyles = (theme) => {
  const styles = {
    // Full Page Container: used for creating a full page container. Beware using it inside anything that sets a height,
    // like a Grid, because it uses 100vh for height, which will be 100vh of the parent container, not the full page.
    fullPageContainer: {
      width: "100wh",
      height: "100vh",
    },
    // Drawer: used for creating a fixed width drawer. Useful for menus and sidebars
    drawer: {
      width: 420,
      backgroundColor: theme.palette.generalUi.background,
    },
    // Input container: used for creating a container for a text input
    inputContainer: {
      textAlign: "left",
      width: "100%",
      margin: theme.spacing(4, 0),
    },
    // input text field: used for creating a text input
    inputTextField: {
      width: "100%",
    },
    //input error text: used for creating an error text for a text input
    inputErrorText: {
      color: theme.palette.error.main,
      fontSize: "0.75rem",
      textAlign: "left",
    },
    // Submit button: used for creating a submit button
    submitButton: {
      width: "100%",
      margin: theme.spacing(4, 0),
    },
  };

  return styles;
};
