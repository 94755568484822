import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

const BlueTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: props =>
      props.disabled ? '#ccc' : theme.palette.primary.main,
    color: props => (props.disabled ? '#666' : theme.palette.common.white),
  },
  popper: {
    zIndex: '1',
  },
}))(Tooltip)

const ValueLabelComponent = props => {
  const { children, open, value, disabled } = props

  const valueText = value => {
    const hours = Math.floor(value / 60)
    const minutes = value % 60
    const formattedHours = hours < 10 ? `0${hours}` : hours
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    return `${formattedHours}:${formattedMinutes}`
  }

  return (
    <BlueTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={valueText(value)}
      disabled={disabled}
    >
      {children}
    </BlueTooltip>
  )
}

export default ValueLabelComponent
