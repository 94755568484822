import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import {
  Drawer,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import {
  CustomInputLabel,
  CustomTextField,
} from '../../components/restyled-mui/CustomInputs'

const sitesListWidth = 345
const tabletSidebarWidth = 48

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    padding: '15px 30px',
    width: props =>
      props.fullscreen
        ? '100%'
        : `calc(${sitesListWidth} - ${theme.spacing(4)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
  customSubtitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: tabletSidebarWidth,
    zIndex: 1400,
    backgroundColor: theme.palette.menu.bg,
    borderRight: `${theme.palette.menu.border} 1px solid`,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
  },
  menuItemIcon: {
    textAlign: 'center',
    minWidth: '100%',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  menuIcon: {
    color: theme.palette.menu.icons,
  },
  selectedMenuIcon: {
    color: theme.palette.primary.main,
  },
  tickIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}))

const PortfolioOverviewPanel = props => {
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const { container, userSetups, setSetup, selectedSetup, loading } = props

  const isEmptyOrContainsUndefined =
    userSetups.length === 0 || userSetups.some(x => x === undefined)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Drawer
        open={true}
        variant={'permanent'}
        anchor={'left'}
        ModalProps={{
          keepMounted: true,
          container: container.current,
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: { position: 'absolute' },
          className: classes.drawerPaper,
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        onClose={toggleOpen}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.formContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={clsx(classes.formSubtitle, classes.customSubtitle)}
              >
                Setup selection
              </Typography>
              {!isEmptyOrContainsUndefined ? (
                <Grid item md={12}>
                  <div className={classes.tooltipContainer}>
                    <CustomInputLabel>Setup Name</CustomInputLabel>
                    <Tooltip
                      title="You can search for a value by typing in the box!"
                      placement="top"
                      arrow
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                    >
                      <span>
                        <IconButton
                          style={{ padding: '0' }}
                          aria-label="info"
                          disableTouchRipple
                        >
                          <HelpIcon
                            fontSize="small"
                            className={classes.helpIcon}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Autocomplete
                      disabled={loading}
                      variant="outlined"
                      size="small"
                      options={userSetups}
                      value={selectedSetup}
                      autoHighlight
                      onChange={(event, value) => {
                        setSetup(value)
                      }}
                      renderInput={params => (
                        <CustomTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: { padding: '1px 5px' },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <CustomInputLabel>
                  No available setup for selection or error occurred.
                </CustomInputLabel>
              )}
            </Grid>
          </Grid>
        </div>
      </Drawer>
      <div className={classes.childrenContainer}>{props.children}</div>
    </>
  )
}

export default PortfolioOverviewPanel
