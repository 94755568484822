import React, { useEffect, useState, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Box, Typography } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'
import { transformSelectedSetup } from '../../api/utils'

const useStyles = makeStyles(theme => ({
  customSubtitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '30px',
  },
}))

const MetricsTimeSeriesChart = props => {
  const classes = useStyles(props)
  const { chartData, chartName, selectedSetup, setTrackDisableStatus } = props
  const [chartIsReady, setChartIsReady] = useState(false)
  const containerRef = useRef(null)
  const TIMESTAMP = 'Timestamp' // that should not change in future

  const timezone =
    loadStateFromLocalStorage('timezone') !== undefined
      ? loadStateFromLocalStorage('timezone')
      : 'Etc/UTC'

  const setups = loadStateFromLocalStorage('setups')
  const unit =
    loadStateFromLocalStorage('unit') !== undefined
      ? loadStateFromLocalStorage('unit')
      : 'kW'

  const unitsConversion = (clientUnit, clientResolution, data) => {
    // default unit is kW
    const ratio = 60 / clientResolution // 60 --> 1 hour to minutes
    const convert = (item, multiplier, applyRatio, exclusions = []) => {
      const newItem = { ...item }
      Object.keys(newItem).forEach(key => {
        const shouldConvert =
          key !== TIMESTAMP && newItem[key] !== 0 && newItem[key] !== null

        if (shouldConvert) {
          if (!exclusions.includes(key)) {
            // applyration is only for kWh and MWh
            newItem[key] = applyRatio
              ? (newItem[key] / ratio) * multiplier
              : newItem[key] * multiplier
          } else {
            newItem[key] = newItem[key] * multiplier
          }
        }
      })
      return newItem
    }

    if (clientUnit === 'kWh') {
      return data.map(item =>
        convert(item, 1, true, ['Availability', 'Capacity']),
      )
    }
    if (clientUnit === 'MW') {
      return data.map(item => convert(item, 0.001, false, []))
    }
    if (clientUnit === 'MWh') {
      return data.map(item =>
        convert(item, 0.001, true, ['Availability', 'Capacity']),
      )
    }
    return data
  }

  const createLineSeries = (root, xAxis, yAxis, seriesName, valueYField) => {
    const formatNumberUnit = unit === 'MWh' || unit === 'MW' ? '#.#' : '#.'
    const initObj = {
      name: seriesName,
      xAxis: xAxis,
      yAxis: yAxis,
      connect: true, // flag for null values
      valueYField: valueYField,
      valueXField: TIMESTAMP,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        labelText: `[bold]{valueY.formatNumber('${formatNumberUnit}')}`,
      }),
    }

    let lineSeries = null
    if (seriesName === 'Online') {
      lineSeries = am5xy.LineSeries.new(root, {
        ...initObj,
        fill: am5.color(0x33ff00),
        stroke: am5.color(0x33ff00),
      })
    } else if (seriesName === 'Metering') {
      lineSeries = am5xy.LineSeries.new(root, {
        ...initObj,
        fill: am5.color(0x0000ff),
        stroke: am5.color(0x0000ff),
      })
    } else if (seriesName === 'Forecast_50') {
      lineSeries = am5xy.LineSeries.new(root, {
        ...initObj,
        fill: am5.color(0xcc0000),
        stroke: am5.color(0xcc0000),
      })
    } else if (seriesName === 'Availability') {
      lineSeries = am5xy.LineSeries.new(root, {
        ...initObj,
        fill: am5.color(0xff0000),
        stroke: am5.color(0xff0000),
      })
    }
    // else if (seriesName === 'Forecast_25') {
    //   lineSeries = am5xy.LineSeries.new(root, {
    //     ...initObj,
    //     name: '25th-50th percentile',
    //     valueYField: 'Forecast_50',
    //     valueXField: valueXField,
    //     openValueYField: 'Forecast_25',
    //   })
    //   lineSeries.strokes.template.set('strokeWidth', 0.1)
    //   lineSeries.fills.template.setAll({
    //     fillOpacity: 0.3,
    //     visible: true,
    //   })
    //   return lineSeries
    // }
    else {
      lineSeries = am5xy.LineSeries.new(root, initObj)
    }
    lineSeries.strokes.template.set('strokeWidth', 1.5)
    return lineSeries
  }

  const createSeriesFromData = (root, xAxis, yAxis, data, unit, resolution) => {
    const series = []

    if (data.length !== 0) {
      const seriesNames = Object.keys(data[0])
      seriesNames.forEach(name => {
        if (name !== TIMESTAMP) {
          const seriesName = name
          const valueYField = name
          const lineSeries = createLineSeries(
            root,
            xAxis,
            yAxis,
            seriesName,
            valueYField,
          )
          const convertedData = unitsConversion(unit, resolution, data, name)
          lineSeries.data.setAll(convertedData)
          series.push(lineSeries)
        }
      })
    } else {
      console.error('Metrics data is empty.')
    }
    return series
  }

  const creatSBLineSeries = (
    root,
    sbxAxis,
    sbyAxis,
    valueYField,
    scrollbarX,
  ) => {
    const sbseries = scrollbarX.chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: sbxAxis,
        yAxis: sbyAxis,
        connect: false,
        valueYField: valueYField,
        valueXField: TIMESTAMP,
      }),
    )
    sbseries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3,
    })

    return sbseries
  }

  const createSBSeriesFromData = (root, scrollbarX, data) => {
    const sbxAxis = scrollbarX.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'hour', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          opposite: false,
          strokeOpacity: 0,
        }),
      }),
    )

    const sbyAxis = scrollbarX.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )

    const sbseries = []
    if (data.length !== 0) {
      const seriesNames = Object.keys(data[0])
      seriesNames.forEach(name => {
        if (name !== TIMESTAMP) {
          const valueYField = name
          const sbLineSeries = creatSBLineSeries(
            root,
            sbxAxis,
            sbyAxis,
            valueYField,
            scrollbarX,
          )
          sbLineSeries.data.setAll(data)
          sbseries.push(sbLineSeries)
        }
      })
    } else {
      console.error('Metrics data is empty.')
    }

    return sbseries
  }

  useEffect(() => {
    const initializeChart = () => {
      try {
        if (typeof am5.registry.rootElements !== 'undefined') {
          am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id === chartName) {
              root.dispose()
            }
          })
        }
        const root = am5.Root.new(chartName)
        // root.setThemes([am5themes_Animated.new(root)])
        root._logo.dispose()
        root.timezone = am5.Timezone.new(timezone)

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            wheelY: 'zoomX',
            layout: root.verticalLayout,
          }),
        )

        // Create power Y-axis
        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            marginRight: 20,
            extraTooltipPrecision: unit === 'MWh' || unit === 'MW' ? 1 : 0,
            renderer: am5xy.AxisRendererY.new(root, {}),
          }),
        )
        const powerLabel = am5.Label.new(root, {
          rotation: -90,
          text:
            unit === 'kW' || unit === 'MW'
              ? `Power (${unit})`
              : `Production (${unit})`,
          y: am5.p50,
          centerX: am5.p50,
        })
        yAxis.children.unshift(powerLabel)

        // Create Y-axis - add extra space right
        const yExtraSpaceRight = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxPrecision: 0,
            syncWithAxis: yAxis,
            renderer: am5xy.AxisRendererY.new(root, {
              opposite: true,
            }),
          }),
        )
        yExtraSpaceRight.children.moveValue(
          am5.Label.new(root, {
            text: '',
            y: am5.p50,
            centerX: am5.p50,
          }),
          0,
        )

        // Create time X-Axis
        const xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            groupData: false,
            marginTop: 20,
            tooltipDateFormat: 'yyyy-MM-dd HH:mm',
            maxDeviation: 0.1,
            baseInterval: { timeUnit: 'minute', count: 15 },
            renderer: am5xy.AxisRendererX.new(root, {}),
          }),
        )
        const timeLabel = am5.Label.new(root, {
          text: timezone,
          x: am5.percent(50),
        })
        xAxis.children.push(timeLabel)
        xAxis.get('renderer').grid.template.setAll({
          strokeOpacity: 0,
        })
        xAxis.get('dateFormats')['month'] = 'yyyy-MM-dd'
        xAxis.get('dateFormats')['day'] = 'yyyy-MM-dd\nHH:mm'
        xAxis.get('dateFormats')['hour'] = 'yyyy-MM-dd\nHH:mm'
        xAxis.get('dateFormats')['minute'] = 'yyyy-MM-dd\nHH:mm'

        const setupName = transformSelectedSetup(selectedSetup)
        const resolution = setups.find(
          item => item.name === setupName,
        ).resolution

        const series = createSeriesFromData(
          root,
          xAxis,
          yAxis,
          chartData,
          unit,
          resolution,
        )
        series.forEach(lineSeries => {
          chart.series.push(lineSeries)
        })

        // Add scrollbarX with series data
        const scrollbarX = am5xy.XYChartScrollbar.new(root, {
          orientation: 'horizontal',
          height: 75,
        })
        chart.set('scrollbarX', scrollbarX)
        createSBSeriesFromData(root, scrollbarX, chartData)

        // Add cursor
        chart.set(
          'cursor',
          am5xy.XYCursor.new(root, {
            behavior: 'zoomXY',
            xAxis: xAxis,
            yAxis: yAxis,
          }),
        )

        xAxis.set(
          'tooltip',
          am5.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        )

        yAxis.set(
          'tooltip',
          am5.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        )

        // add legend
        const legend = chart.children.push(
          am5.Legend.new(root, {
            layout: root.gridLayout,
            centerX: am5.percent(50),
            x: am5.percent(50),
          }),
        )
        legend.data.setAll(chart.series.values)

        let menu = am5plugins_exporting.ExportingMenu.new(root, {})

        // TODO - enable when set new chartData. currently just work for one serie
        am5plugins_exporting.Exporting.new(root, {
          menu: menu,
          pdfOptions: { disabled: true },
          pdfdataOptions: { disabled: true },
          jsonOptions: { disabled: true },
          htmlOptions: { disabled: true },
          dateFields: [TIMESTAMP],
          dateFormat: 'yyyy-MM-dd HH:mm',
          dataSource: chartData,
          filePrefix: `${selectedSetup}_metrics_timeseries`,
        })

        // ENABLE / DISABLE vertical lines
        let labelButtonEnable = am5.Label.new(root, {
          text: 'Enable vertical lines',
          fontSize: 12,
          fontWeight: '200',
          fill: 'black',
        })

        let labelButtonDisable = am5.Label.new(root, {
          text: 'Disable vertical lines',
          fontSize: 12,
          fontWeight: '200',
          fill: 'black',
        })

        let verticalLinesButton = chart.plotContainer.children.push(
          am5.Button.new(root, {
            dx: 10,
            dy: 10,
            layer: 40,
            label: labelButtonEnable,
          }),
        )
        verticalLinesButton.get('background').setAll({
          cornerRadiusTL: 5,
          cornerRadiusTR: 5,
          cornerRadiusBR: 5,
          cornerRadiusBL: 5,
          fill: am5.color(0x000000),
          fillOpacity: 0.2,
        })
        let isToggled = false
        verticalLinesButton.events.on('click', function (ev) {
          isToggled = !isToggled

          if (isToggled) {
            xAxis.get('renderer').grid.template.setAll({
              strokeOpacity: 1,
              strokeWidth: 1,
              strokeDasharray: [10, 5, 2, 5],
            })
            verticalLinesButton.children.clear()
            verticalLinesButton.children.push(labelButtonDisable)
          } else {
            xAxis.get('renderer').grid.template.setAll({
              strokeOpacity: 0,
            })
            verticalLinesButton.children.clear()
            verticalLinesButton.children.push(labelButtonEnable)
          }
        })

        // catch the event when chart is ready
        let timeout
        root.events.on('frameended', exportChart)
        function exportChart() {
          if (timeout) {
            clearTimeout(timeout)
          }
          timeout = setTimeout(function () {
            root.events.off('frameended', exportChart)
            setChartIsReady(true)
            setTrackDisableStatus(false)
          }, 100)
        }
      } catch (error) {
        console.error('Error in Metrics TimeSerise chart:', error)
      }
    }

    if (chartData && containerRef.current && !chartIsReady) {
      setTimeout(() => {
        initializeChart()
      }, 100)
    }
  }, [chartData, chartIsReady])

  return (
    <>
      {!chartIsReady && (
        <Box sx={{ width: '100%', marginTop: '400px' }}>
          <Typography
            variant="h4"
            className={clsx(classes.formSubtitle, classes.customSubtitle)}
          >
            Generating graph
          </Typography>
          <LinearProgress />
        </Box>
      )}
      <div
        ref={containerRef}
        id={chartName}
        style={{
          width: '100%',
          height: '750px',
          opacity: chartIsReady ? '1' : '0',
        }}
      ></div>
    </>
  )
}

export default MetricsTimeSeriesChart
