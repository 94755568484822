import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CustomCheckbox } from '../../components/restyled-mui/CustomInputs'

export const CustomList = withStyles(_theme => ({
  root: {
    padding: 0,
  },
}))(List)
export const CustomListItem = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 4),
  },
  container: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.table.cellBg,
    },
    borderBottom: `1px solid ${theme.palette.table.cellBorder}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))(ListItem)

export const CustomListItemText = withStyles(_theme => ({
  root: {},
}))(ListItemText)

export const CustomListItemSecondaryAction = withStyles(_theme => ({
  root: {},
}))(ListItemSecondaryAction)

const useStyles = makeStyles(theme => ({
  listContainer: {
    border: `1px solid ${theme.palette.table.cellBorder}`,
    marginTop: 10,
    marginBottom: 20,
    minHeight: 'fit-content',
    maxHeight: '500px',
  },
}))

const PermissionsSelection = props => {
  const classes = useStyles(props)
  const { allPermissions, user, setUser } = props
  const [syncPermissions, setSyncModulePermissions] = useState([])
  const userPermissions = user.permissions

  useEffect(() => {
    const syncLists = allPermissions.map(item => ({
      name: item,
      checked: userPermissions.includes(item.toLowerCase()),
    }))
    setSyncModulePermissions(syncLists)
  }, [allPermissions])

  const toggleAllPermissions = e => {
    const isChecked = e.target.checked

    const newSyncList = syncPermissions.map(permission => ({
      ...permission,
      checked: isChecked,
    }))

    setSyncModulePermissions(newSyncList)

    if (isChecked) {
      const modulePermissionAll = newSyncList.map(permission =>
        permission.name.toLowerCase(),
      )
      setUser({ ...user, permissions: modulePermissionAll })
    } else {
      setUser({ ...user, permissions: [] })
    }
  }

  const handleCheckboxClick = index => {
    const currentItem = syncPermissions[index]
    currentItem.checked = !currentItem.checked
    setSyncModulePermissions([
      ...syncPermissions.slice(0, index),
      currentItem,
      ...syncPermissions.slice(index + 1),
    ])
    const updatedModulePermissions = syncPermissions
      .filter(item => item.checked)
      .map(item => item.name.toLowerCase())
    setUser({ ...user, permissions: updatedModulePermissions })
  }

  return (
    <div className={classes.listContainer}>
      <div className="MuiListItem-container">
        <CustomListItemText
          style={{ padding: '10px 16px' }}
          primary="Select All"
        />
        <CustomListItemSecondaryAction>
          <CustomCheckbox
            indeterminate={
              userPermissions.length > 0 &&
              syncPermissions.some(permission => !permission.checked)
            }
            checked={
              syncPermissions.length > 0 &&
              syncPermissions.every(permission => permission.checked)
            }
            onClick={toggleAllPermissions}
          ></CustomCheckbox>
        </CustomListItemSecondaryAction>
      </div>

      <CustomList>
        {syncPermissions.map((item, index) => (
          <CustomListItem key={index}>
            <CustomListItemText primary={item.name} />
            <CustomListItemSecondaryAction>
              <CustomCheckbox
                checked={item.checked}
                onClick={() => handleCheckboxClick(index)}
              />
            </CustomListItemSecondaryAction>
          </CustomListItem>
        ))}
      </CustomList>
    </div>
  )
}

export default PermissionsSelection
