import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import AnalysisPanel from '../panels/AnalysisPanel'
import { Grid, CircularProgress } from '@material-ui/core'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid'
import Api from '../api'
import NoSite from '../components/NoSite'
import ErrorsMap from '../charts/Analysis/ErrorsMap'
import { setSelectedUserSetup } from '../redux/actions/userSetups'

const columns = [
  {
    field: 'parameter_key',
    headerName: 'Name',
    flex: 1.5,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Lat',
    headerName: 'Latitude',
    type: 'number',
    flex: 1,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Lon',
    headerName: 'Longitude',
    type: 'number',
    flex: 1,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'InstalledCapacityKW',
    headerName: 'Installed Capacity (kW)',
    type: 'number',
    flex: 1.5,
    headerClassName: 'super-app-theme--header',
  },

  {
    field: 'MAE',
    headerName: 'MAE',
    type: 'number',
    flex: 1,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'NMAE',
    headerName: 'NMAE (%)',
    type: 'number',
    flex: 1,
    headerClassName: 'super-app-theme--header',
  },
]

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />{' '}
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles(theme => ({
  chart: {
    textAlign: 'center',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  mainGrid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '30px',
  },
  tableGrid: {
    height: '45%',
    display: 'flex',
  },
  mapGrid: {
    height: '45%',
    display: 'flex',
  },
  graph: {
    height: '80%',
    width: '80%',
    minHeight: '40rem',
    minWidth: '10rem',
  },
  subCaption: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: '600',
  },
  paramContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0px 30px',
  },
  downloadDataButton: {
    width: '100%',
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
}

const AnalysisPage = props => {
  const classes = useStyles(props)
  const [loading, setLoading] = useState(false)
  const [mapData, setMapData] = useState([])
  const [selectedPlants, setSelectedPlants] = useState([])
  const [tableInfoData, setTableInfoData] = useState([])

  const { selectedSetup, userSetups, setSelectedUserSetup } = props

  const [analysisData, setAnalysisData] = useState(null)
  const disableButton = analysisData !== null || loading ? true : false

  let initNodata = {
    heading: 'No setup selected',
    body: 'Select a setup and adjust parameters in order to start analysis',
  }

  if (selectedSetup) {
    initNodata = {
      heading: '',
      body: '',
    }
  }

  const [nodataContent, setNodataContent] = useState(initNodata)

  useEffect(() => {
    if (selectedPlants && selectedPlants.length > 0) {
      setMapData(
        tableInfoData
          .filter(plant => selectedPlants.includes(plant.parameter_key))
          .map(plant => ({
            latitude: plant.Lat,
            longitude: plant.Lon,
            installedCapacity: plant.InstalledCapacityKW,
            technology: plant.Technology,
            NMAE: (plant.NMAE * 100).toFixed(3),
            name: plant.parameter_key,
          })),
      )
    } else if (tableInfoData && tableInfoData.length > 0) {
      setMapData(
        tableInfoData.map(plant => ({
          latitude: plant.Lat,
          longitude: plant.Lon,
          installedCapacity: plant.InstalledCapacityKW,
          technology: plant.Technology,
          NMAE: (plant.NMAE * 100).toFixed(3),
          name: plant.parameter_key,
        })),
      )
    }
  }, [tableInfoData, selectedPlants])

  const startAnalysis = async data => {
    setLoading(true)
    try {
      const response = await Api.Analysis.createAnalysis(data)

      if (response.status === 204) {
        setAnalysisData(null)
        setNodataContent({
          heading: `No data for selected setup: ${selectedSetup}`,
          body: '',
        })
      }
      if (response.status === 422) {
        setAnalysisData(null)
        setNodataContent({
          heading: `Error running query for selected setup: ${selectedSetup}`,
          body: '',
        })
      }

      if (response.status === 200) {
        const result = response.data
        const formatData = result.map((obj, idx) => ({
          ...obj,
          id: idx + 1,
          NMAE: (obj.NMAE * 100).toFixed(3),
        }))

        setTableInfoData(result)

        setAnalysisData(formatData)
      }
      setLoading(false)
    } catch (error) {
      setAnalysisData(null)
      if (error.response.status === 411) {
        handleNoDataContentUpdate({
          heading: 'ERROR:',
          body: `${error.response.data.error}`,
        })
      } else {
        setNodataContent({
          heading: `Error fetching Analysis data: ${selectedSetup}`,
          body: '',
        })
      }
      console.error('Error fetching Analysis data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleStartAnalysis = data => {
    startAnalysis(data)
  }

  const handleSetupChange = selectedValue => {
    setSelectedUserSetup(selectedValue)
  }

  const handleNoDataContentUpdate = newContent => {
    setNodataContent(newContent)
    setAnalysisData(null)
  }

  const resultPanel = () => {
    return analysisData && selectedSetup !== null ? (
      <Grid container spacing={4} className={classes.mainGrid}>
        <Grid item xs={12} className={classes.mapGrid}>
          <ErrorsMap data={mapData} />
        </Grid>
        <Grid item xs={12} className={classes.tableGrid}>
          <DataGrid
            rows={analysisData}
            rowHeight={30}
            columns={columns}
            getRowId={row => row.parameter_key}
            checkboxSelection
            disableColumnSelector
            disableColumnMenu
            rowSelectionModel={selectedPlants}
            onRowSelectionModelChange={setSelectedPlants}
            slots={{ toolbar: CustomToolbar }}
            sx={overridesWeirdResizing}
          />
        </Grid>
      </Grid>
    ) : (
      <NoSite
        heading={nodataContent.heading}
        body={nodataContent.body}
      ></NoSite>
    )
  }

  return (
    <AnalysisPanel
      onSetupChange={handleSetupChange}
      setAnalysisParameters={handleStartAnalysis}
      userSetups={userSetups}
      handleNoDataContentUpdate={handleNoDataContentUpdate}
      disableButton={disableButton}
      setAnalysisData={setAnalysisData}
      loading={loading}
    >
      {loading ? (
        <div className={classes.centerItem}>
          <CircularProgress />
        </div>
      ) : (
        resultPanel()
      )}
    </AnalysisPanel>
  )
}

const mapStateToProps = state => ({
  selectedSetup: state.userSetups.selectedSetup,
  userSetups: state.userSetups.userSetups,
})

const mapDispatchToProps = {
  setSelectedUserSetup,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPage)
