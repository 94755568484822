import { STATUS } from "../types";

export const status = {
  loading: false,
  snackbar: {
    open: false,
    message: "",
    severity: null,
  },
};

export default function statusReducer(state = status, action) {
  const { type, message } = action;
  switch (type) {
    case STATUS.FETCHING:
      return {
        ...state,
        loading: true,
        snackbar: {
          open: false,
          message: "",
          severity: null,
        },
      };
    case STATUS.SUCCESS:
      return {
        ...state,
        loading: false,
        snackbar: {
          open: true,
          message: message,
          severity: "success",
        },
      };
    case STATUS.ERROR:
      return {
        ...state,
        loading: false,
        snackbar: {
          open: true,
          message: message,
          severity: "error",
        },
      };
    case STATUS.RESET:
      return {
        ...state,
        loading: false,
        snackbar: {
          open: false,
          message: state.snackbar.message,
          severity: state.snackbar.severity,
        },
      };
    default:
      return state;
  }
}
