import { USER } from '../types'
import { saveStateToLocalStorage } from '../utilsLocalStorage'

export function logout() {
  return {
    type: USER.LOGOUT,
  }
}

export const loggedUser = user => {
  return dispatch => {
    dispatch({
      type: USER.LOADED,
      payload: {
        username: user.username,
        password: user.password,
        permissions: user.permissions,
        setups: user.setups,
        userId: user.userId,
        timezone: user.timezone,
        unit: user.unit,
      },
    })

    saveStateToLocalStorage(user.username, 'username')
    saveStateToLocalStorage(user.timezone, 'timezone')
    saveStateToLocalStorage(user.unit, 'unit')
  }
}
