import { SET_USER } from '../types'

const initialState = {
  selectedSetup: null,
  userSetups: [],
}

const userSetups = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER.SETUP:
      return { ...state, selectedSetup: action.payload }
    case SET_USER.SETUPS:
      return { ...state, userSetups: action.payload }
    default:
      return state
  }
}

export default userSetups
