import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip, IconButton } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  label: {
    fontSize: 14,
    color: theme.palette.primary.main,
    // textTransform: 'uppercase',
  },
  value: {
    fontSize: 28,
    fontWeight: '400',
    // backgroundColor: 'lightgray',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
}))

const PortfolioHistoryParameter = ({
  label,
  value,
  arrowValue,
  hasTooltip = false,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>
        {label}
        {hasTooltip && (
          <Tooltip
            title="Add vertical lines to indicate the start of a new intra-day period of day-ahead period!"
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <span>
              <IconButton
                style={{ padding: '0', marginLeft: '5px' }}
                aria-label="info"
                disableTouchRipple
              >
                <HelpIcon fontSize="small" className={classes.helpIcon} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Typography>

      <Typography className={classes.value}>{value}</Typography>
      {arrowValue && (
        <div style={{ display: 'flex' }}>
          {arrowValue >= 0 ? (
            <ArrowUpwardIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <ArrowDownwardIcon fontSize="small" style={{ color: 'red' }} />
          )}

          <Typography
            style={{
              marginLeft: '5px',
              fontSize: '15px',
              color: arrowValue >= 0 ? 'green' : 'red',
            }}
          >
            {arrowValue}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default PortfolioHistoryParameter
