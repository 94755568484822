import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Users from '../../src/features/settings-users/Users'
import User from '../../src/features/settings-users/User'
import PageNotFound from './PageNotFound'
import StatusSnackbar from '../features/settings-users/StatusSnackbar'
import Setups from '../features/settings-setups/Setups'
import Setup from '../features/settings-setups/Setup'

const App = props => {
  const { history } = props

  const goToHomepage = () => {
    history.push('/')
  }

  return (
    <>
      <StatusSnackbar />
      <Switch>
        <Route exact path={`/settings`}>
          {/* <Redirect to={`/settings/setups`} /> */}
          <Redirect to={`/settings/users`} />
        </Route>

        <Route
          path={`/settings/users`}
          exact
          render={props => <Users {...props} />}
        />
        <Route
          path={`/settings/users/create`}
          exact
          render={props => <User {...props} creating={true} />}
        />
        <Route
          path={`/settings/users/:id`}
          exact
          render={props => <User {...props} creating={false} />}
        />

        {/* <Route
          path={`/settings/setups`}
          exact
          render={props => <Setups {...props} />}
        />

        <Route
          path={`/settings/setups/create`}
          exact
          render={props => <Setup {...props} creating={true} />}
        />
        <Route
          path={`/settings/setups/:id`}
          exact
          render={props => <Setup {...props} creating={false} />}
        /> */}

        <Route render={props => <PageNotFound goToHomepage={goToHomepage} />} />
      </Switch>
    </>
  )
}

export default withRouter(App)
