import { SETUPS } from '../types'
import { handleError } from '../../utils/error'
import { STATUS } from '../types'
import { changeStatus } from './status'
import Api from '../../api'
import { userSetups } from './userSetups'

export const getSetups = () => {
  return dispatch => {
    return Api.Setups.getSetups()
      .then(res => {
        dispatch({ type: SETUPS.LOADED, payload: res.data.setups })
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const createSetup = data => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))
    return Api.Setups.createSetup(data)
      .then(res => {
        const permissions = getState().userPermissions.permissions
        const updatedSetups = [...getState().setups, data]
        dispatch(userSetups(updatedSetups, permissions))

        dispatch(changeStatus(STATUS.SUCCESS, res.data.msg))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const deleteSetup = id => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))
    return Api.Setups.deleteSetup(id)
      .then(res => {
        const updatedSetups = getState().setups.filter(setup => setup.id !== id)
        const permissions = getState().userPermissions.permissions
        dispatch(userSetups(updatedSetups, permissions))
        dispatch({ type: SETUPS.UPDATED, payload: updatedSetups })
        dispatch(changeStatus(STATUS.SUCCESS, res.data.msg))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}

export const updateSetup = updatedSetup => {
  return (dispatch, getState) => {
    dispatch(changeStatus(STATUS.FETCHING))
    return Api.Setups.updateSetup(updatedSetup)
      .then(res => {
        const updatedSetups = getState().setups.map(setup =>
          setup.id === updatedSetup.id ? { ...updatedSetup } : setup,
        )

        dispatch({ type: SETUPS.UPDATED, payload: updatedSetups })

        dispatch(changeStatus(STATUS.SUCCESS, 'Setup updated'))
      })
      .catch(err => {
        handleError(dispatch, err)
      })
  }
}
