import axios from 'axios'

// const apikey = process.env.REACT_APP_API_KEY

export const configureApi = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  //   axios.defaults.headers.common['x-apikey'] = apikey
  //   axios.defaults.headers.common['api-key'] = apikey
}
