import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const CustomButton = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    height: theme.spacing(9),
    width: theme.spacing(29),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
}))(Button)

export const PrimaryButton = withStyles(theme => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
}))(CustomButton)

export const SecondaryButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.generalUi.borders}`,
    backgroundColor: theme.palette.common.white,
    fontWeight: 600,
  },
}))(CustomButton)

export const TertiaryButton = withStyles(theme => ({
  root: {
    fontWeight: 600,
    color: theme.palette.text.defaultSub,
  },
}))(CustomButton)
